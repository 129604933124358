import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import translations from '../../locals/translation';
// import { FiSearch } from "react-icons/fi";
import { useSelector } from 'react-redux';
import { GrUpload } from "react-icons/gr";
import GlobalURL from '../BaseURL';
import Swal from 'sweetalert2';

const EditLegend = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const stateData = useLocation();
    const navigate = useNavigate();
    const formData = new FormData()
    const [file, setFile] = useState(null);
    const [addBtn, setAddBtn] = useState(false);
    // const [legendSearch, setLegendSearch] = useState('');
    // const [isPending, startTransition] = useTransition();

    console.log(' stateData: ', stateData);
    // console.log('isPending: ', isPending);

    const [countryData, setCountryData] = useState();
    const [errMsg, setErrMsg] = useState(false);
    // const [selectedPlayer, setSelectedPlayer] = useState();
    // const [suggestedPlayers, setSuggestedPlayers] = useState();
    // const [searchedPlayerUid, setSearchedPlayerUid] = useState();
    const [allTeam, setAllTeam] = useState([]);

    const [playerData, setPlayerData] = useState({ ...stateData.state });

    //get all team
    useEffect(() => {
        fetch(`${GlobalURL}/api/team/getTeamsForTM`, {
            method: "GET",
            mode: "cors",
            headers: {
                "ngrok-skip-browser-warning": "69420",
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    console.log("status: ", response);
                }
            }).then(res => {
                setAllTeam(res.data);
                console.log('res.datpa: ', res.data);
            })
            .catch((err) => {
                console.log("error occured: ", err);
            });
    }, [])

    // const handlePlayerSearch = (e) => {
    //     setSuggestedPlayers(null)
    //     let searchWord = e.target.value;
    //     // setPlayerSearch(searchWord);
    //     setPlayerData({ ...playerData, [e.target.name]: e.target.value });

    //     if (searchWord.length > 1) {
    //         let div = document.querySelector(".legend-dropdown");
    //         div.classList.add('legend-active');

    //         startTransition(() => {
    //             fetch(`${GlobalURL}/api/dev/team_moderator/searchAllUser`, {
    //                 method: "POST",
    //                 mode: "cors",
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
    //                     "ngrok-skip-browser-warning": "69420",
    //                 },
    //                 body: JSON.stringify({ key: searchWord, team_id: localStorage.getItem("team_id") })
    //             })
    //                 .then((res) => {
    //                     if (res.status === 200) {
    //                         return res.json();
    //                     }
    //                     else {
    //                         console.log("error: ", res);
    //                     }
    //                 })
    //                 .then((resp) => {
    //                     setSuggestedPlayers(resp.data);
    //                     console.log('resp.data: ', resp.data);
    //                 })
    //                 .catch(function (e) {
    //                     console.log('err: ', e);
    //                     setSuggestedPlayers([])
    //                     Swal.fire("Error", "server error, please try again!", "error")
    //                 });
    //         })
    //     } else {
    //         let div = document.querySelector(".legend-dropdown");
    //         div.classList.remove('legend-active');
    //     }
    // }

    useEffect(() => {
        fetch(`${GlobalURL}/api/dev/getCountries`, {
            method: "GET",
            mode: "cors",
            headers: {
                "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem('access-token'))}`,
                "ngrok-skip-browser-warning": "69420",
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    // Swal.fire("Jugadore añadido con éxito!");
                    return response.json();
                } else {
                    console.log("status: ", response)
                    // setErrMsg('¡Error del Servidor!');
                }
            }).then((resp) => {
                setCountryData(resp.data)
                console.log('resp.data: ', resp.data);
            })
            .catch((err) => {
                console.log("error occured: ", err);
                // setErrMsg('¡Error del Servidor!');
                // setAddBtn(false);
            });
    }, [])

    const handlePlayerData = (e) => {
        setPlayerData({ ...playerData, [e.target.name]: e.target.value });
    }

    const handleFileSelect = (e) => {
        setFile(e.target.files[0]);
    };

    //add player
    const handleAddPlayer = (e) => {
        setAddBtn(true);
        // formData.append("team_id",localStorage.getItem("team_id"));
        if (file) {
            formData.append("media", file);
        }
        for (let formfields in playerData) {
            formData.append(formfields, playerData[formfields]);
        }
        formData.append("team_id", localStorage.getItem("team_id"));
        // if (searchedPlayerUid) {
        //     formData.append("user_id", searchedPlayerUid);
        // }
        if (playerData && file) {
            fetch(`${GlobalURL}/api/dev/team_moderator/addPlayerDetails`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem('access-token'))}`,
                    "ngrok-skip-browser-warning": "69420",
                },
                body: formData
            })
                .then((response) => {
                    setAddBtn(false);
                    setErrMsg(false);
                    if (response.status === 200) {
                        navigate(-1);
                        Swal.fire(t("Leyends añadido con éxito!"));
                        return response.json();
                    } else {
                        console.log("status: ", response)
                        setErrMsg('¡Error del Servidor!');
                    }
                })
                .catch((err) => {
                    console.log("error occured: ", err);
                    setErrMsg('¡Error del Servidor!');
                    setAddBtn(false);
                });
        } else {
            //apply error message for player if required fields are empty
            setErrMsg('¡Campos requeridos!')
            setAddBtn(false);
        }
    }

    const getTeamId = (e) => {
        let id;
        for (let item of allTeam) {
            if (item.team_name === e.target.value) {
                id = item.team_id;
                break;
            }
        }
        // setCountryId(id);
        setPlayerData({ ...playerData, [e.target.name]: id });
    }

    const getCountryId = (e) => {
        let id;
        for (let item of countryData) {
            if (item.name === e.target.value) {
                id = item.id;
                break;
            }
        }
        // setCountryId(id);
        setPlayerData({ ...playerData, [e.target.name]: id });
    }

    // handle the ignore btn
    // const handleIgnore = (e) => {
    //     e.preventDefault();
    //     let div = document.querySelector(".legend-dropdown");
    //     div.classList.remove('legend-active');
    // }

    // handle click of link blue btn
    // const handleLinkLegend = (e) => {
    //     e.preventDefault();
    //     let div = document.querySelector(".legend-dropdown");
    //     div.classList.remove('legend-active');
    //     console.log("selected data: ", selectedPlayer);
    //     let userDetails = suggestedPlayers.find((user) => {
    //         // eslint-disable-next-line
    //         return user.user_id == selectedPlayer?.target?.closest('.legendlist').id;
    //     })
    //     console.log('userDetails: ', userDetails);
    //     setPlayerData((prev) => ({ ...prev, player_name: `${userDetails?.first_name} ${userDetails?.last_name}` }));
    // }

    //handle - highlight the legend selection. 
    // const handleSelect = (e) => {
    //     selectedPlayer?.target?.closest('.legendlist').classList.remove('highlight');
    //     e.target.closest('.legendlist').classList.add('highlight');
    //     setSelectedPlayer(e);
    //     // setSearchedPlayerData();
    //     setSearchedPlayerUid(() => e?.target?.closest('.legendlist').id);
    //     console.log('selectedPlayer.id: ', e?.target?.closest('.legendlist').id);
    // }

    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='teamAdminisration-profile'>
                        <Container fluid>
                            <Row>
                                <Col xs lg="3"></Col>
                                <Col xs lg="6">
                                    <div className='news-header'>
                                        <div className='back-sec' onClick={() => { navigate(-1, { replace: true }) }}
                                            style={{ cursor: "pointer" }}>
                                            <img src="./img/volver.png" alt="" />
                                        </div>
                                        <h3 className='header-title inter-page-header-title'>{t('EDITAR LEYENDA')}</h3>
                                        <div className='search-icon'>
                                            {/* <FiSearch /> */}
                                        </div>
                                    </div>
                                    <div className='profile-desc-sec'>
                                        <Row className='profile-row-sec'>
                                            <Col xs lg="2">

                                            </Col>
                                            <Col xs lg="8">
                                                <div className='user-admin-profile user-enc-img'>
                                                    <div className="user-admin-profile-img">
                                                        <div className=' img-video-form-control'>

                                                            <div className='editnewscard-img'>
                                                                {
                                                                    <img src={file
                                                                        ? `${URL.createObjectURL(file)}`
                                                                        : (stateData?.state?.image_url)
                                                                    } alt="" />
                                                                }
                                                                <div className='img-edit-icon'>
                                                                    <GrUpload />
                                                                    <input id="uploadFile" onChange={handleFileSelect}
                                                                        type="file" className="upload" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h5>
                                                        <div className='common-form-control'>
                                                            {/* <input placeholder='Nombre del jugador' name='player_name'
                                                                value={playerData.player_name} onChange={handlePlayerData} /> */}
                                                            {/* <label>Nombre Completo</label> */}

                                                            <input type="search"
                                                                placeholder='Nombre del jugador'
                                                                name='player_name'
                                                                // onChange={handlePlayerSearch}
                                                                value={playerData.player_name}
                                                            />
                                                            {/* <div className={`legend-dropdown ${((playerData.player_name)?.length > 1) ? 'legend-active' : ''}`}>
                                                                <div className='legend-box'>
                                                                    <ul className=''>
                                                                        {suggestedPlayers
                                                                            ? (suggestedPlayers?.length
                                                                                ? (suggestedPlayers?.map((playerData, i) => {
                                                                                        return (
                                                                                            <li key={i} id={playerData.user_id}
                                                                                                className='legendlist'
                                                                                                onClick={handleSelect}
                                                                                            >
                                                                                                <div className='legendprofile'>
                                                                                                    <img src={playerData?.image_url} alt="" />
                                                                                                    {playerData.is_verified
                                                                                                        && (<div className='legendstatusbtn'>
                                                                                                            <img src="./img/diamante1.png" alt="" />
                                                                                                        </div>)}
                                                                                                </div>
                                                                                                <div className='legendprofilename'>
                                                                                                    <span className='legPro-name mb-1' >
                                                                                                        <strong >{playerData.first_name} {playerData.last_name}</strong>
                                                                                                    </span>
                                                                                                    <span className='legPro-username fw-light'>
                                                                                                        {playerData?.username}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </li>
                                                                                        )
                                                                                    })
                                                                                )
                                                                                : (<li className='legendlist ' >
                                                                                    <div className='legendprofile'>
                                                                                    </div>
                                                                                    <div className='legendprofilename'>
                                                                                        <span className='legPro-name mb-1' >
                                                                                            <strong >{t('No se encontró ningún jugador con este nombre')}</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                </li>
                                                                                )
                                                                            )
                                                                            : (
                                                                                <li className='legendlist  justify-content-center'>
                                                                                    <div className='legendprofile'>
                                                                                    </div>
                                                                                    <div className='legendprofilename text-center'>
                                                                                        <span className='legPro-name mb-1' >
                                                                                            <strong >{t('Enviando')}...</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        }
                                                                    </ul>

                                                                    <div className='legendBtn'>
                                                                        <button className='red-btn' onClick={handleIgnore}>{t('IGNORAR')}</button>
                                                                        <button className='blue-btn' onClick={handleLinkLegend}>{t('VINCULAR')}</button>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </h5>
                                                </div>
                                            </Col>

                                            <Col xs lg="2"></Col>
                                        </Row>
                                    </div>
                                    <div className='admin-profile-tabs-sec'>

                                    </div>
                                    <div className='profile-tabs-sec legend-tabs'>
                                        <Tabs
                                            defaultActiveKey="perfil"
                                            id="fill-tab-example"
                                            fill
                                        >
                                            <Tab eventKey="perfil" title={t("PERFIL")}>

                                                <div className='profile-sec-tabs'>

                                                    <div className='personal-info-sec'>
                                                        <div className='personal-info-heading'>
                                                            <h5>{t('Datos Personales')}</h5>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>
                                                                        {t("Edad")}
                                                                    </p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input placeholder={t('Edad del jugador')} name='age'
                                                                            value={playerData.age} onChange={handlePlayerData} />
                                                                    </div>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t('Posición')}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <select name='position'
                                                                            onChange={handlePlayerData}
                                                                            value={playerData.position}
                                                                        >
                                                                            <option value="">{t('seleccione Posición')}</option>
                                                                            <option value="goalkeeper">{t('Portero')}</option>
                                                                            <option value="attacker">{t('Adelante')}</option>
                                                                            <option value="midfielder">{t('Mediocampista')}</option>
                                                                            <option value="defender">{t('Defensor')}</option>
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="4">
                                                                    <p className='info-heading'>{t('país')}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <input type="text" list="data"
                                                                        name='country'
                                                                        placeholder={t('¡seleccione el nombre del equipo para recibir!')}
                                                                        onChange={getCountryId} />
                                                                    <datalist id="data">
                                                                        {countryData?.map((item, key) =>
                                                                            <option key={key} id={item.id} value={item.name} />
                                                                        )}
                                                                    </datalist>
                                                                </Col>

                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="4">
                                                                    <p className='info-heading'>{t("otra nacionalidad")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <input type="text" list="data2"
                                                                        name='other_nationality'
                                                                        placeholder={t('¡seleccione el nombre del equipo para recibir!')}
                                                                        onChange={getCountryId} />
                                                                    <datalist id="data2">
                                                                        {countryData?.map((item, key) =>
                                                                            <option key={key} id={item.id} value={item.name} />
                                                                        )}
                                                                    </datalist>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t('género')}</p>
                                                                </Col>

                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <select name='gender'
                                                                            onChange={handlePlayerData}
                                                                            value={playerData?.gender}
                                                                        >
                                                                            <option value="">{t('Seleccione género')}</option>
                                                                            <option value="male">{t("Masculino")}</option>
                                                                            <option value="female">{t("Femenino")}</option>
                                                                        </select>
                                                                    </div>
                                                                </Col>

                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t('Pie preferido')}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input placeholder={t('ingrese Pie preferido')} name='preferred_foot'
                                                                            value={playerData.preferred_foot} onChange={handlePlayerData} />
                                                                    </div>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("Peso")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input placeholder={t('ingrese Peso')} name='weight'
                                                                            value={playerData.weight} onChange={handlePlayerData} />
                                                                    </div>

                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t('Estatura')}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input placeholder={t('ingrese Estatura')} name='height'
                                                                            value={playerData.height} onChange={handlePlayerData} />
                                                                    </div>

                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("Fecha de nacimiento")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input type='date' placeholder={t('ingrese Estatura')} name='birthdate'
                                                                            value={playerData.birthdate} onChange={handlePlayerData} />
                                                                    </div>

                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t('País de nacimiento')}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input placeholder={t('ingrese País de nacimiento')} name='place_of_birth'
                                                                            value={playerData.place_of_birth} onChange={handlePlayerData} />
                                                                    </div>

                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                    </div>

                                                    <div className='personal-info-sec career-details-sec'>
                                                        <div className='personal-info-heading'>
                                                            <h5>{t("Datos de su carrera")}</h5>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("identificador de Twitter")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input placeholder={t('ingrese identificador de Twitter')} name='twitter_handle'
                                                                            value={playerData.twitter_handle} onChange={handlePlayerData} />
                                                                    </div>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("Equipo Actual")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <input type="text" list="data1"
                                                                        name='current_team'
                                                                        placeholder={t('¡seleccione el nombre del equipo!')}
                                                                        onChange={getTeamId} />
                                                                    <datalist id="data1">
                                                                        {allTeam.map((member, key) => {
                                                                            // console.log("fmdfc: ",allTeam)
                                                                            return <option key={key} id={member.team_id} value={member.team_name} />
                                                                        }
                                                                        )}
                                                                    </datalist>

                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t('Equipo Anterior')}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <input type="text" list="data1"
                                                                        name='previous_team'
                                                                        placeholder={t('¡seleccione el nombre del equipo!')}
                                                                        onChange={getTeamId} />
                                                                    <datalist id="data1">
                                                                        {allTeam.map((member, key) => {
                                                                            // console.log("fmdfc: ",allTeam)
                                                                            return <option key={key} id={member.team_id} value={member.team_name} />
                                                                        }
                                                                        )}
                                                                    </datalist>

                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t('Competición Actual')}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input placeholder={t('ingrese Competición Actual')} name='current_competition'
                                                                            value={playerData.current_competition} onChange={handlePlayerData} />
                                                                    </div>

                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t('Competición Anterior')}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input placeholder={t('ingrese Competición Anterior')} name='previous_competition'
                                                                            value={playerData.previous_competition} onChange={handlePlayerData} />
                                                                    </div>

                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t('valor neto')}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input placeholder={t('ingrese valor neto')} name='net_worth'
                                                                            value={playerData.net_worth} onChange={handlePlayerData} />
                                                                    </div>

                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t('Elo')}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input placeholder={t('ingrese Elo')} name='elo'
                                                                            value={playerData.elo} onChange={handlePlayerData} />
                                                                    </div>

                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t('Equipo histórico')}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input type="text" list="data4" name='historical_team'
                                                                            placeholder={t('¡seleccione el nombre del equipo para recibir!')}
                                                                            onChange={getTeamId} />
                                                                        <datalist id="data4">
                                                                            {allTeam?.map((item, key) =>
                                                                                <option key={key} id={item.team_id} value={item.team_name} />
                                                                            )}
                                                                        </datalist>
                                                                    </div>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t('número de camiseta')}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input placeholder={t('ingrese número de camiseta')} name='jersey_number'
                                                                            value={playerData.jersey_number} onChange={handlePlayerData} />
                                                                    </div>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("Último Dorsal")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input placeholder={t('ingrese Último Dorsal')} name='ultimate_dorsal'
                                                                            value={playerData.ultimate_dorsal} onChange={handlePlayerData} />
                                                                    </div>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("Dorsal más común")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input placeholder={t('ingrese Dorsal más común')} name='most_common_dorsal'
                                                                            value={playerData.most_common_dorsal} onChange={handlePlayerData} />
                                                                    </div>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>

                                            {/* <Tab eventKey="estadistics" title="ESTADISTICAS"> */}
                                            {/* <div className='statistics-tabs'>
                                                <div className='feild-position-sec'>
                                                    <Row>
                                                        <Col xs lg="6">
                                                            <div className='feild-sec'>
                                                                <h4 className='feild-sec-heading'>Posición en el Campo</h4>
                                                                <div className='feild-card'>
                                                                    <p>Posición principal</p>
                                                                    <h5 className='edit-sec' contentEditable='false' id='position'>
                                                                    {teamPlayer?.position
                                                                        ? `${teamPlayer?.position}` 
                                                                        : 'N/A'
                                                                    }
                                                                    </h5>
                                                                </div>
                                                                <div className='feild-card '>
                                                                    <p>Otras posiciones</p>
                                                                    <h5 className='edit-sec' contentEditable='false' id='position'>
                                                                    {teamPlayer?.position
                                                                        ? `${teamPlayer?.position}` 
                                                                        : 'N/A'
                                                                    }
                                                                    </h5>
                                                                </div>
                                                            </div>  
                                                            
                                                        </Col>
                                                        <Col xs lg="3">
                                                            <div className='feild-sec-info'>
                                                                <p className='green-text'>
                                                                    posicion principal
                                                                </p>
                                                                <p className='per-sec'>
                                                                    <span>Delantero</span>
                                                                    <span>84%</span>
                                                                </p>
                                                                <p className='green-text'>
                                                                    posicion principal
                                                                </p>
                                                                <p className='per-sec'>
                                                                    <span>Delantero</span>
                                                                    <span>84%</span>
                                                                </p>
                                                            </div>
                                                        </Col>
                                                        <Col xs lg="3">
                                                            <div className='feild-sec-img'>
                                                                <img src="./img/ftFeild.png" alt="" />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs lg="6">
                                                            <div className='attribute-sec feild-sec'>
                                                                <h4 className='feild-sec-heading'>Atributos</h4>
                                                                <div className='feild-card'>
                                                                    <p>Ritmo</p>
                                                                    <h5 className='edit-sec' contentEditable='false' id='ritmo'>
                                                                    {teamPlayer?.ritmo
                                                                        ? `${teamPlayer?.ritmo}` 
                                                                        : 'N/A'
                                                                    }
                                                                    </h5>
                                                                </div>
                                                                <div className='feild-card'>
                                                                    <p>Tiro</p>
                                                                    <h5 className='edit-sec' contentEditable='false' id='tiro'>
                                                                    {teamPlayer?.tiro
                                                                        ? `${teamPlayer?.tiro}` 
                                                                        : 'N/A'
                                                                    }
                                                                    </h5>
                                                                </div>
                                                                <div className='feild-card'>
                                                                    <p>Pase</p>
                                                                    <h5 className='edit-sec' contentEditable='false' id='pase'>
                                                                    {teamPlayer?.pase
                                                                        ? `${teamPlayer?.pase}` 
                                                                        : 'N/A'
                                                                    }
                                                                    </h5>
                                                                </div>
                                                                <div className='feild-card'>
                                                                    <p>Regate</p>
                                                                    <h5 className='edit-sec' contentEditable='false' id='regate'>
                                                                    {teamPlayer?.regate
                                                                        ? `${teamPlayer?.regate}` 
                                                                        : 'N/A'
                                                                    }
                                                                    </h5>
                                                                </div>
                                                                <div className='feild-card'>
                                                                    <p>Defensa</p>
                                                                    <h5 className='edit-sec' contentEditable='false' id='defensa'>
                                                                    {teamPlayer?.defensa
                                                                        ? `${teamPlayer?.defensa}` 
                                                                        : 'N/A'
                                                                    }
                                                                    </h5>
                                                                </div>
                                                                <div className='feild-card'>
                                                                    <p>Fisica</p>
                                                                    <h5 className='edit-sec' contentEditable='false' id='fisica'>
                                                                    {teamPlayer?.fisica
                                                                        ? `${teamPlayer?.fisica}` 
                                                                        : 'N/A'
                                                                    }
                                                                    </h5>
                                                                </div>
                                                                
                                                            </div>                                                        
                                                        </Col>
                                                        <Col xs lg="6">
                                                            <div className='attribute-img-sec'>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div> */}
                                            {/* </Tab> */}

                                            {/* <Tab eventKey="Equipos" title="EQUIPOS"> */}
                                            {/* <div className='equipment-tab-sec'>
                                                <div className='equipment-card-sec'>
                                                    <div className='equipment-card'>
                                                        <h5>Agregar equipo</h5>
                                                    </div>
                                                    <div className='equipment-card'>
                                                        <h5>Agregar descripción</h5>
                                                    </div>
                                                    <div className='equipment-list-sec'>
                                                        <ul className='equipment-list'>
                                                            <li>
                                                                <span className='equp-list-content'>
                                                                    <img src="./img/eq1.png" alt="" />
                                                                    <span className='equ-list-desc'>River Plate (Argentina): Julián Alvarez comenzó su carrera profesional en el club argentino River Plate, donde jugó desde 2003 hasta 2005.</span>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span className='equp-list-content'>
                                                                    <img src="./img/eq2.png" alt="" />
                                                                    <span className='equ-list-desc'>River Plate (Argentina): Julián Alvarez comenzó su carrera profesional en el club argentino River Plate, donde jugó desde 2003 hasta 2005.</span>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span className='equp-list-content'>
                                                                    <img src="./img/eq3.png" alt="" />
                                                                    <span className='equ-list-desc'>River Plate (Argentina): Julián Alvarez comenzó su carrera profesional en el club argentino River Plate, donde jugó desde 2003 hasta 2005.</span>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div> */}
                                            {/* </Tab> */}

                                            {/* <Tab eventKey="trofeos" title="TROFEOS"> */}
                                            {/* <div className='trophy-tab-sec'>
                                                <div className='trophy-sec-card'>
                                                    <div className='trophy-card'>
                                                        <h5>Ingrese Trofeo</h5>
                                                    </div>
                                                    <div className='trophy-card'>
                                                        <h5>Ingrese Fecha</h5>
                                                    </div>
                                                </div>
                                                <div className='trophy-profile-sec'>
                                                    <div className='trophy-profile-card'>
                                                        <div className='trophy-img'>
                                                            <img src="./img/trophyImg.png" alt="" />
                                                        </div>
                                                        <div className='trophy-details-sec'>
                                                            <h4>CONMEBOL_UEFA Cup of Champion Winner</h4>
                                                            <p className='trophydate'>21/22</p>
                                                        </div>
                                                    </div>
                                                    <div className='trophy-profile-card'>
                                                        <div className='trophy-img'>
                                                            <img src="./img/trophyImg.png" alt="" />
                                                        </div>
                                                        <div className='trophy-details-sec'>
                                                            <h4>CONMEBOL_UEFA Cup of Champion Winner</h4>
                                                            <p className='trophydate'>21/22</p>
                                                        </div>
                                                    </div>

                                                    <div className='trophy-profile-card'>
                                                        <div className='trophy-img'>
                                                            <img src="./img/trophyImg.png" alt="" />
                                                        </div>
                                                        <div className='trophy-details-sec'>
                                                            <h4>CONMEBOL_UEFA Cup of Champion Winner</h4>
                                                            <p className='trophydate'>21/22</p>
                                                        </div>
                                                    </div>
                                                    <div className='trophy-profile-card'>
                                                        <div className='trophy-img'>
                                                            <img src="./img/trophyImg.png" alt="" />
                                                        </div>
                                                        <div className='trophy-details-sec'>
                                                            <h4>CONMEBOL_UEFA Cup of Champion Winner</h4>
                                                            <p className='trophydate'>21/22</p>
                                                        </div>
                                                    </div>
                                                    <div className='trophy-profile-card'>
                                                        <div className='trophy-img'>
                                                            <img src="./img/trophyImg.png" alt="" />
                                                        </div>
                                                        <div className='trophy-details-sec'>
                                                            <h4>CONMEBOL_UEFA Cup of Champion Winner</h4>
                                                            <p className='trophydate'>21/22</p>
                                                        </div>
                                                    </div>
                                                    <div className='trophy-profile-card'>
                                                        <div className='trophy-img'>
                                                            <img src="./img/trophyImg.png" alt="" />
                                                        </div>
                                                        <div className='trophy-details-sec'>
                                                            <h4>CONMEBOL_UEFA Cup of Champion Winner</h4>
                                                            <p className='trophydate'>21/22</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            {/* </Tab> */}

                                        </Tabs>

                                    </div>
                                </Col>
                                <Col xs lg="3">

                                    <div className='profile-btns-sec'>
                                        <div className='profile-btsn-inner'>
                                            <div className='err-spn text-danger text-center'> {errMsg} </div>
                                            <button className='green-btn my-5'
                                                onClick={handleAddPlayer}>
                                                {addBtn
                                                    ? (<>
                                                        <span className="spinner-border spinner-border-sm"
                                                            role="status" aria-hidden="true"></span>
                                                        <span className="sr-only">&nbsp; {t('Enviando')}...</span>
                                                    </>)
                                                    : (t('Agregar jugador'))
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditLegend;
