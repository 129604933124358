import { useNavigate, useOutletContext } from "react-router-dom";
import translations from '../../../locals/translation';
import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import GlobalURL from "../../BaseURL";
// import { STERLING } from "../../BaseURL";
// import { SUNBIRD } from "../../BaseURL";
import { GrUpload } from "react-icons/gr";
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PieChart from "./PieChart";
import Swal from 'sweetalert2';
import "./style.css";

const HomePage = () => {

  const language = useSelector(state => state.language.value);
  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  let navigate = useNavigate();
  let { setFlag, flagStripColor, setRequestStripColor } = useOutletContext();
  const [allTeam, setAllTeam] = useState()
  const [shieldData, setShieldData] = useState();
  const [selectedTeam, setSelectedTeam] = useState();
  const [teamToggle, setTeamToggle] = useState(false);
  const [pieChartData, setPieChartData] = useState();
  const [shiledUpdated, setShieldUpdated] = useState(false);
  const [previewShield, setPreviewShield] = useState();
  const [ambassador, setAmbassador] = useState(); //create before to get ambassadore details
  console.log('ambassador: ', ambassador);
  const [addAmbassador, setAddAmbassador] = useState();
  const [allCountry, setAllCountry] = useState();
  // const [ambassadoreFile, setAmbassadoreFile] = useState(null);
  // const [previewAmbassadore, setPreviewAmbassadore] = useState(null);
  const [maleAmbassador, setMaleAmbassador] = useState();
  const [femaleAmbassador, setFemaleAmbassador] = useState();

  // const handleAddAmbassador = (e) => {
  //   setAddAmbassador({ ...addAmbassador, [e.target.name]: e.target.value })
  // }

  const [theme, setTheme] = useState();
  // const [colourBoxCount, setColourBoxCount] = useState(1);
  // const [flagColorData, setFlagColorData] = useState([]);

  const [file, setFile] = useState(null);
  const formData = new FormData();

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [showAmbassador, setShowAmbassador] = useState(false);
  const handleShowAmbassodor = () => setShowAmbassador(true);
  const handleCloseAmbassodor = () => {
    setShowAmbassador(false);
    setAddAmbassador();
    // setPreviewAmbassadore(null);
    setMaleAmbassador(null)
    setFemaleAmbassador(null)
  }

  // const [showEditAmbassador, setShowEditAmbassador] = useState(false);
  // const handleShowEditAmbassodor = () => setShowEditAmbassador(true);
  // const handleCloseEditAmbassodor = () => {
  //   setShowEditAmbassador(false);
  //   setAddAmbassador();
  //   setPreviewAmbassadore(null);
  // }

  const [newMaleAmbass, setNewMaleAmbass] = useState();
  const [previewMaleAmbassador, setPreviewMaleAmbassador] = useState(null);
  const [maleAmbassadorFile, setMaleAmbassadorFile] = useState(null);

  const [newFemaleAmbass, setNewFemaleAmbass] = useState();
  const [previewFemaleAmbassador, setPreviewFemaleAmbassador] = useState(null);
  const [femaleAmbassadorFile, setFemaleAmbassadorFile] = useState(null);

  // const [flagColor, setFlagColor] = useState({
  //   theme_color1: '',
  //   theme_color2: '',
  //   theme_color3: ''
  // }
  // );

  const [colorBoxStatus, setColorBoxStatus] = useState({
    theme_color1: true,
    theme_color2: true,
    theme_color3: true,
  });

  const [flagColor, setFlagColor] = useState({});

  const handleHexColorCode = (e) => {
    setFlagColor({ ...flagColor, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    console.log("In UE: flagColor footer in home: ", flagStripColor);
    setFlagColor(() => flagStripColor);
  }, [flagStripColor])

  useEffect(() => {
    let flagData = localStorage.getItem("flag");
    let teamName = localStorage.getItem("team_name");
    if (flagData || teamName) {
      setSelectedTeam({ team_logo_url: flagData, alt: teamName, team_name: teamName })
    } else {
      setSelectedTeam({ team_logo_url: "./img/LogoMess.png", alt: "MessIce", team_name: "Mess ICE" })
    }
  }, [])

  //get ambassador
  useEffect(() => {
    setMaleAmbassador({});
    setFemaleAmbassador({});
    setNewMaleAmbass('');
    setNewFemaleAmbass('');
    setPreviewMaleAmbassador(null);
    setPreviewFemaleAmbassador(null);

    console.log("addAmbassador?.country_code: ", addAmbassador?.country_code);
    fetch(`${GlobalURL}/api/dev/user/banner/getCountryLeaderTM`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": JSON.parse(localStorage.getItem("access-token")),
        "ngrok-skip-browser-warning": "69420",
      },
      body: JSON.stringify({ country_code: addAmbassador?.country_code })
    })
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          return response.json();
        }
      }).then(res => {
        setAmbassador(res.data);
        res.data.forEach(item => {
          if (item.gender === "male" && item?.image_url) {
            setMaleAmbassador(item);
          }
          else if (item.gender === "female" && item?.image_url) {
            setFemaleAmbassador(item);
          }
        })
        console.log('res.data: ', res.data);
        // setAddAmbassador(res.data);
      })
      .catch((err) => {
        console.log("error occured: ", err);
      });
    // eslint-disable-next-line
  }, [addAmbassador?.country_code])

  //get all country
  useEffect(() => {
    // fetch(`${GlobalURL}/api/team/getTeamsForTM`, {
    fetch(`${GlobalURL}/api/dev/getCountries`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Contant-Type": "application/json",
        "Authorization": JSON.parse(localStorage.getItem("access-token")),
        "ngrok-skip-browser-warning": "69420",
      }
    })
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          return response.json();
        }
      }).then(res => {
        setAllCountry(res.data);
        console.log('countryy data: ', res.data);
      })
      .catch((err) => {
        console.log("error occured: ", err);
      });
    // eslint-disable-next-line
  }, [])

  //get all team
  useEffect(() => {
    // fetch(`${GlobalURL}/api/team/getTeamsForTM`, {
    fetch(`${GlobalURL}/api/dev/team_moderator/getTeamsForTM`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Contant-Type": "application/json",
        "Authorization": JSON.parse(localStorage.getItem("access-token")),
        "ngrok-skip-browser-warning": "69420",
      }
    })
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          return response.json();
        }
      }).then(res => {
        setAllTeam(res.data);
      })
      .catch((err) => {
        console.log("error occured: ", err);
      });
    // eslint-disable-next-line
  }, [])

  //get all team
  useEffect(() => {
    // fetch(`${GlobalURL}/api/team/getTeamsForTM`, {
    fetch(`${GlobalURL}/api/dev/team_moderator/getTeamsForTM`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Contant-Type": "application/json",
        "Authorization": JSON.parse(localStorage.getItem("access-token")),
        "ngrok-skip-browser-warning": "69420",
      }
    })
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          return response.json();
        }
      }).then(res => {
        setAllTeam(res.data);
      })
      .catch((err) => {
        console.log("error occured: ", err);
      });
    // eslint-disable-next-line
  }, [shiledUpdated])

  useEffect(() => {
    fetch(`${GlobalURL}/api/dev/team_moderator/getMembershipDataByTeam`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "69420",
      },
      body: JSON.stringify({ team_id: localStorage.getItem("team_id") })
    })
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          return response.json();
        }
        else {
          console.log("status: ", response);
        }
      }).then(res => {
        setPieChartData(res.data);
        console.log('from home : res.data: ', res.data);
      })
      .catch((err) => {
        console.log("error occured: ", err);
      });
    // eslint-disable-next-line 
  }, [localStorage.getItem("team_id")])

  //handle team flag
  const handleTeamSelection = (e) => {
    // console.log('e: ', e);
    let teamGotcha = ''
    if (e.target.nodeName === 'DIV') {
      teamGotcha = e.target.id;
    } else {
      teamGotcha = e.target.closest(".team-list-link").id;
    }

    // console.log('teamGotcha: ', teamGotcha);
    allTeam.forEach(item => {
      if (item.team_name === teamGotcha) {
        localStorage.setItem("flag", item.team_logo_url);
        localStorage.setItem("team_id", item.team_id);
        localStorage.setItem("team_name", item.team_name);
        localStorage.setItem("national", item.national);
        localStorage.setItem("tier", item.tier);
        setSelectedTeam(item);
        setTeamToggle(false);
        setFlag(item.team_logo_url);

        Swal.fire(
          {
            title: `<img src=${item.team_logo_url} style=width:80px;> </br>${t("!Equipo seleccionado!")} </br> ${item.team_name}.`,
          })
      }
    })
  }

  const handleChartAccess = () => {
    navigate('/digitalmanager');
  }

  const hanldeSaveFlagColor = () => {
    let team_id = shieldData?.team_id;
    if (team_id && theme) {

      // if (flagColor.theme_color1 && flagColor.theme_color2 && flagColor.theme_color3) {
      // if (flagColor.theme_color1 && theme) {
      //   // console.log("final payload: ", { ...flagColor, team_id });
      //   // https://truly-thankful-marten.ngrok-free.app/api/dev/team_moderator/addThemeColor
      //   fetch(`${GlobalURL}/api/dev/team_moderator/addThemeColor`, {
      //     method: "POST",
      //     mode: "cors",
      //     headers: {
      //       "Content-Type": "application/json",
      //       "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem('access-token'))}`,
      //       "ngrok-skip-browser-warning": "69420",
      //     },
      //     body: JSON.stringify({ ...flagColor, team_id, theme })
      //   })
      //     .then((response) => {
      //       if (response.status === 200) {
      //         Swal.fire("Equipo", "añadido con éxito!", "success");
      //         handleClose();
      //         return response.json();
      //       } else {
      //         console.log("err: ", response)
      //       }
      //     })
      //     .catch((err) => {
      //       console.log("error occured: ", err);
      //     });
      // }
      // else {
      //   Swal.fire("Equipo", "¡Seleccione el color requerido!", "info");
      // }
      // if (file) {

      file && formData.append('media', file);
      formData.append("team_id", team_id);
      formData.append("theme", theme);
      formData.append("theme_color1", flagColor.theme_color1);
      formData.append("theme_color2", flagColor.theme_color2);
      formData.append("theme_color3", flagColor.theme_color3);
      Swal.fire("actualizando datos del escudo...");

      fetch(`${GlobalURL}/api/dev/team_moderator/changeTeamShield`, {
        method: "POST",
        mode: "cors",
        headers: {
          // "Content-Type": "application/json",
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem('access-token'))}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: formData
      })
        .then((response) => {
          if (response.status === 200) {
            Swal.fire(t("Equipo"), t("añadido con éxito!"), "success");
            return response.json();
          } else {
            console.log("err: ", response)
          }
        })
        .then((res) => {
          console.log("resp: ", res);
          // let flagURL;

          if (res.data.team_logo_url) {
            console.log("inside if: block");
            // flagURL = res.data.team_logo_url;
            setFlag(res.data.team_logo_url);
            localStorage.setItem("flag", res.data.team_logo_url);

            setSelectedTeam({
              ...selectedTeam,
              team_logo_url: res.data.team_logo_url,
              team_name: res.data.team_name
            });
          } else if (res.data.team_logo_url_light) {

            // flagURL = res.data.team_logo_url_light;
            setFlag(res.data.team_logo_url_light);
            localStorage.setItem("flag", res.data.team_logo_url_light);

            setSelectedTeam({
              ...selectedTeam,
              team_logo_url: res.data.team_logo_url_light,
              team_name: localStorage.getItem("team_name")
            });
          }

          // console.log('res.team_logo_url: in homepage ', flagURL);

          setRequestStripColor(prev => !prev);
          setShieldUpdated(prevs => !prevs);
          // setFlagColor({
          //   theme_color1: res.data.theme_color1,
          //   theme_color2: res.data.theme_color2,
          //   theme_color3: res.data.theme_color3
          // });

        })
        .catch((err) => {
          console.log("error occured: ", err);
        }).finally(() => {
          handleClose();
        });
      // }
    } else {
      if (!team_id)
        Swal.fire(t("Equipo"), t("¡Selecciona equipo/club!"), "info");
      else
        Swal.fire(t("Tema"), t("¡Selecciona el Tema!"), "info");
    }
  }

  const getTeamId = (e) => {
    let id, logo;
    for (let item of allTeam) {
      if (item.team_name === e.target.value) {
        id = item.team_id;
        logo = item.team_logo_url;
        setFlag(item.team_logo_url);

        localStorage.setItem("flag", item.team_logo_url);
        localStorage.setItem("team_id", item.team_id);
        localStorage.setItem("team_name", item.team_name);

        setSelectedTeam({
          ...selectedTeam,
          team_logo_url: item.team_logo_url,
          team_name: item.team_name
        });
        setRequestStripColor((prev) => !prev);
        console.log("selected the team in the popup: ", item);
        break;
      }
    }

    setPreviewShield(null);
    setShieldData({ ...shieldData, team_id: id, shield_logo: logo, shieldName: '' });
  }

  const handleFileSelect = (e) => {
    setFile(e.target.files[0]);
    if (e.target.files[0])
      setPreviewShield(URL.createObjectURL(e?.target?.files[0]));
    setShieldData({ ...shieldData, shieldName: e.target.value.replace("C:\\fakepath\\", "") })
  };

  const handleCloseBox = (e) => {
    // if (colourBoxCount > 3) {
    //   setColourBoxCount(1);
    // } else {
    //   setColourBoxCount(colourBoxCount - 1);
    // }
    // console.log("target value:  ", e.target.id);
    // console.log("close box clicked: ");
    // let newObj = {}
    // Object.keys(flagColor).forEach((item) => {
    //   console.log("inside filter: ", item, " value: ", flagColor[item])
    //   if (item !== e.target.id) {
    //     newObj = { ...newObj, [item]: flagColor[item] }
    //   }
    // })
    // console.log('newObj: ', newObj);
    // setFlagColor(newObj)
    // const flagColorObjects = flagColorData.filter((item) => {
    //   console.log('item.count: ', item.count);
    //   return Number(item.count) !== Number(e.target.id);
    // })

    // setFlagColorData(flagColorObjects);
    // console.log('flagColorObjects: ', flagColorObjects);
    console.log("box id: ", e.target.id);
    // console.log("color_Data: ",flagColorData);
    if (flagStripColor) {
      console.log("Old color present: ", flagStripColor[e.target.id]);
      setFlagColor({ ...flagColor, [e.target.id]: flagStripColor[e.target.id] });
      setColorBoxStatus({ ...colorBoxStatus, [e.target.id]: true });
    } else {
      setFlagColor({ ...flagColor, [e.target.id]: '' });
      setColorBoxStatus({ ...colorBoxStatus, [e.target.id]: true });
    }
  }

  const handleBoxShow = (e) => {
    console.log("box show btn clicked: ");
    for (let boxContent in colorBoxStatus) {
      console.log('boxContent: ', boxContent);
      console.log('colorBoxStatus[boxContent]: ', colorBoxStatus[boxContent]);
      if (colorBoxStatus[boxContent]) {
        setColorBoxStatus((prev) => ({ ...prev, [boxContent]: false }))
        return;
      }
    }

    // setFlagColor({ ...flagColor, [`color_theme${Object.keys(flagColor).length}`]: "" })
    // if (colourBoxCount > 3) {
    //   setColourBoxCount(()=>1)
    // }
    // else {
    //   setColourBoxCount((prev) => prev + 1);
    // }
    // setFlagColorData([...flagColorData, {
    //   flagColor: flagColor?.[`theme_color${colourBoxCount}`],
    // }
    // ])
  }

  const handleTheme = (e) => {
    setTheme(e.target.value);
  }

  const handleAmbassadorCountry = (e) => {
    allCountry?.forEach((countryInst) => {
      if (countryInst.name === e.target.value) {
        setAddAmbassador((prev) => ({ ...prev, country_code: countryInst.code, country_name: countryInst.name }));
      }
    })
  }

  const handleAmbassadorSelect = (e) => {
    // setAmbassadoreFile(e.target.files[0]);
    // if (e.target.files[0])
    //   setPreviewAmbassadore(URL.createObjectURL(e?.target?.files[0]));
    // else
    //   sePreviewAmbassadore(null);

    // setMaleAmbassadorFile(null);
    // setPreviewMaleAmbassador("");
    // setNewMaleAmbass("");

    console.log('e.target.name: ', e.target.files[0]);
    if (e.target.name === "male") {
      setMaleAmbassadorFile(e.target.files[0]);
      setPreviewMaleAmbassador(URL?.createObjectURL(e?.target?.files[0]));
      setNewMaleAmbass(e.target.value.replace("C:\\fakepath\\", ""));
    } else {
      setFemaleAmbassadorFile(e.target.files[0]);
      setPreviewFemaleAmbassador(URL?.createObjectURL(e?.target?.files[0]));
      setNewFemaleAmbass(e.target.value.replace("C:\\fakepath\\", ""));
    }
  }

  const submitAmbassador = () => {
    console.log("finale ambassador data:  ", addAmbassador);
    Swal.fire(t("Ambassadore"), t("añadiendo..."))
    let ambassadorFormData = new FormData();

    if (addAmbassador?.country_code
      && addAmbassador?.country_name
      // && addAmbassador?.gender
      && (femaleAmbassadorFile || femaleAmbassador?.image_url)
      && (maleAmbassadorFile || maleAmbassador?.image_url)
    ) {
      ambassadorFormData.append("country_code", addAmbassador?.country_code);
      ambassadorFormData.append("country_name", addAmbassador?.country_name);
      // ambassadorFormData.append("gender", addAmbassador?.gender);
      console.log('maleAmbassadorFile: ', maleAmbassadorFile);
      console.log('femaleAmbassadorFile: ', femaleAmbassadorFile);

      if (maleAmbassadorFile)
        ambassadorFormData.append("male", maleAmbassadorFile);
      else
        ambassadorFormData.append("male", maleAmbassador?.image_url);

      if (femaleAmbassadorFile)
        ambassadorFormData.append("female", femaleAmbassadorFile);
      else
        ambassadorFormData.append("female", femaleAmbassador?.image_url);

      fetch(`${GlobalURL}/api/dev/user/banner/addCountryLeader`, {
        method: "POST",
        mode: "cors",
        headers: {
          // "Contant-Type": "application/json",
          "Authorization": JSON.parse(localStorage.getItem("access-token")),
          "ngrok-skip-browser-warning": "69420",
        },
        body: ambassadorFormData
      })
        .then((response) => {
          if (response.status === 200) {
            Swal.fire(t("Ambassadore"), t("Embajador actualizado"), "success");
            console.log(response);
            setAddAmbassador()
            handleCloseAmbassodor();
            // setPreviewAmbassadore(null);
            setFemaleAmbassador(null);
            setMaleAmbassador(null);
            return response.json();
          }
          else {
            Swal.fire(t("Ambassadore"), t("error en embajador actualizado"), t("error"))
            handleCloseAmbassodor();
          }
        }).catch((err) => {
          console.log("error occured: ", err);

          Swal.fire(t("Ambassadore"), t("error en embajador actualizado"), "error");
        });
    } else {
      Swal.fire(t("Ambassadore"), t("¡Seleccione el campo requerido!"), "info");
    }
  }

  // const handleClearAmbassador = (e) => {
  //   if (!e.currentTarget.value)
  //     console.log("handleClear called: ");
  // }

  const handleCloseMaleAmbassadorImg = () => {
    console.log("male clear called: ");
    setNewMaleAmbass('');
    setPreviewMaleAmbassador(null);
    setMaleAmbassadorFile(null)
  }

  const handleCloseFemaleAmbassadorImg = () => {
    console.log("female clear called: ");
    setNewFemaleAmbass('');
    setPreviewFemaleAmbassador(null);
    setMaleAmbassadorFile(null);
  }

  return (
    <>
      <div className="dashboard">
        <div className="dashboard-container">

          <Container fluid>
            <Row className="justify-content-md-center manage-responsive-home">
              <Col xs lg="3 home-empty-col"></Col>

              <Col xs lg="6">
                <div className="dashboard-main">
                  <div className="dashboard-content">

                    <h1 className="main-title">
                      {t('PANEL DE CONTROL DE MODERADOR DE EQUIPO')}
                    </h1>

                    <div className="d-flex gap-2 justify-content-center trio-btns">
                      <button className='blue-btn' onClick={handleShow}
                        type='submit'>{t('Agregar / Editar equipo escudo')}</button>

                      <button className='blue-btn' onClick={handleShowAmbassodor}
                        type='submit'>{t('Agregar / Editar embajador')}</button>

                      <button className='blue-btn' onClick={() => navigate('/teamtiers')}
                        type='submit'>{t('Administrar niveles de equipo')}</button>
                    </div>

                    {/* <div className="d-flex justify-content-center">
                    </div> */}

                    <div className="dashboard-card-secs">
                      <Row>

                        <Col xs lg="3" onClick={() => { navigate('/proposalvote') }}
                          style={{ cursor: "pointer" }}>
                          <div className="control-card">
                            <div className="card-img">
                              <img src="./img/propuestas.png" alt="" />
                            </div>
                            <div className="card-body">
                              <h4>{t('Propuestas & Votos')}</h4>
                              <p>{t('Descripción')}</p>
                            </div>
                          </div>
                        </Col>

                        <Col xs lg="3">
                          <div className="control-card" onClick={() => navigate('/boardmeeting')}
                            style={{ cursor: "pointer" }}>
                            <div className="card-img">
                              <img src="./img/chaticon.png" alt="" />
                            </div>
                            <div className="card-body">
                              <h4>{t('Salón de Debates')}</h4>
                              <p>{t("Descripción")}</p>
                            </div>
                          </div>
                        </Col>

                        <Col xs lg="3">
                          <div className="control-card" onClick={() => navigate('/news')}
                            style={{ cursor: "pointer" }}>
                            <div className="card-img">
                              <img src="./img/noticias.png" alt="" />
                            </div>
                            <div className="card-body">
                              <h4>{t('Noticias')}</h4>
                              <p>{t("Descripción")}</p>
                            </div>
                          </div>
                        </Col>

                        <Col xs lg="3">
                          <div className="control-card" onClick={() => navigate('/billboard')} style={{ cursor: "pointer" }}>
                            <div className="card-img">
                              <img src="./img/cartelera.png" alt="" />
                            </div>
                            <div className="card-body">
                              <h4>{t('Cartelera')}</h4>
                              <p>{t("Descripción")}</p>
                            </div>
                          </div>
                        </Col>

                        <Col xs lg="3">
                          <div className="control-card" onClick={() => navigate('/streaming')} style={{ cursor: "pointer" }}>
                            <div className="card-img">
                              <img src="./img/streaming.png" alt="" />
                            </div>
                            <div className="card-body">
                              <h4>{t("Streaming")}</h4>
                              <p>{t("Descripción")}</p>
                            </div>
                          </div>
                        </Col>

                        <Col xs lg="3">
                          <div className="control-card" onClick={() => navigate('/experience')} style={{ cursor: "pointer" }}>
                            <div className="card-img">
                              <img src="./img/VIVENCIASMESS_ICE.png" alt="" />
                            </div>
                            <div className="card-body">
                              <h4>{t('Vivencias Mess Ice')}</h4>
                              <p>{t("Descripción")}</p>
                            </div>
                          </div>
                        </Col>

                        <Col xs lg="3">
                          <div className="control-card" onClick={() => navigate('/events')} style={{ cursor: "pointer" }}>
                            <div className="card-img">
                              <img src="./img/Eventos.png" alt="" />
                            </div>
                            <div className="card-body">
                              <h4>{t('Eventos')}</h4>
                              <p>{t("Descripción")}</p>
                            </div>
                          </div>
                        </Col>

                        <Col xs lg="3">
                          <div className="control-card" onClick={() => navigate('/teamadministration')} style={{ cursor: "pointer" }}>
                            <div className="card-img">
                              <img src="./img/AdminEquipo.png" alt="" />
                            </div>
                            <div className="card-body">
                              <h4>{t('Administractión de equipo')} </h4>
                              <p>{t("Descripción")}</p>
                            </div>
                          </div>
                        </Col>

                        <Col xs lg="3">
                          <div className="control-card" onClick={() => navigate('/legendpage')} style={{ cursor: "pointer" }}>
                            <div className="card-img">
                              <img src="./img/Leyendas.png" alt="" />
                            </div>
                            <div className="card-body">
                              <h4>{t('Leyendas')}</h4>
                              <p>{t("Descripción")}</p>
                            </div>
                          </div>
                        </Col>

                        <Col xs lg="3">
                          <div className="control-card" onClick={() => navigate('/multimedia')} style={{ cursor: "pointer" }}>
                            <div className="card-img">
                              <img src="./img/multimedia.png" alt="" />
                            </div>
                            <div className="card-body">
                              <h4>{t('Multimedia')}</h4>
                              <p>{t("Descripción")}</p>
                            </div>
                          </div>
                        </Col>

                        <Col xs lg="3">
                          <div className="control-card" onClick={() => navigate('/institutional')} style={{ cursor: "pointer" }}>
                            <div className="card-img">
                              <img src="./img/Institucional.svg" alt="" />
                            </div>
                            <div className="card-body">
                              <h4>{t('Institucional')}</h4>
                              <p>{t("Descripción")}</p>
                            </div>
                          </div>
                        </Col>

                        <Col xs lg="3">
                          <div className="control-card" onClick={() => navigate('/reachadmin')} style={{ cursor: "pointer" }}>
                            <div className="card-img">
                              <img src="./img/contactaradmin.png" alt="contect_Admin" />
                            </div>
                            <div className="card-body">
                              <h4>{t('Contactar Admin')}</h4>
                              <p>{t("Descripción")}</p>
                            </div>
                          </div>
                        </Col>

                      </Row>

                    </div>
                  </div>
                </div>
              </Col>

              <Col xs lg="3">
                <div className="team-list-char-sec">
                  {teamToggle
                    ? (
                      <div className="manage-team-sec">
                        <div className="manage-team-card">
                          <div className="team-list">
                            <h5 className="card-heading">{t('Administrar Equipos')}</h5>
                            <ul>

                              {allTeam && allTeam.map((team) => {
                                return (
                                  <li key={team.team_id}>
                                    <div className="team-list-link" id={team.team_name}
                                      onClick={handleTeamSelection}>

                                      <span className="team-list-img">
                                        <img className="flag-img-home" src={team.team_logo_url} alt="" />
                                      </span>

                                      <span className="team-list-name">
                                        {team.team_name}
                                      </span>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    )
                    : (
                      <div className="teams-profile flag-div"
                        onClick={() => setTeamToggle(true)}>

                        <h5 className="card-heading">
                          {t('Administrar Equipos')}
                        </h5>

                        <div className="manage-team-img">
                          <img src={selectedTeam?.team_logo_url} alt="" />
                        </div>

                        <p className="team-name">
                          {selectedTeam?.team_name}
                        </p>

                      </div>
                    )
                  }

                  <div className="piechart-sec text-white" onClick={handleChartAccess}>
                    <PieChart UserWithMembership={pieChartData} />

                  </div>

                </div>
              </Col>
            </Row>



            <Modal show={show} className="club-popup" onHide={handleClose}>
              <Modal.Body >

                <div className="form-group selection-input mb-30 ">
                  <label className="mb-2">{t('CARGAR EQUIPO / CLUB')}</label>
                  {/* <input className="text-white" type="text" name="" id="" /> */}

                  <input type="text" list="data1"
                    className="text-white club-selection"
                    name='Selected_team'
                    onClick={(e) => e.target.select(e)}
                    placeholder={t("¡seleccione el nombre del equipo!")}
                    onChange={getTeamId} />

                  <datalist id="data1">
                    {allTeam?.map((member, key) => {
                      return <option key={key} id={member.team_id} value={member.team_name} />
                    }
                    )}
                  </datalist>
                </div>

                <div className="form-group mb-3">
                  <label className="mb-2">{t('ESCUDO DE SELECCIÓN')}</label>

                  <select className="theme-selection"
                    name="theme" value={theme}
                    onChange={handleTheme}>

                    <option>{t('Seleccione el tema')}</option>
                    <option value="dark">{t('Oscuro')}</option>
                    <option value="light">{t('Ligero')}</option>
                  </select>

                  <div className="select-clrImg">
                    <div className="color-picker-btn">
                      <div className='img-edit'>
                        <GrUpload />
                        <input id="uploadFile" onChange={handleFileSelect}
                          type="file" className="upload" readOnly />
                      </div>
                    </div>

                    <div className="select-clrName">
                      <input
                        type="text"
                        value={shieldData?.shieldName}
                        className="text-white club-selection" />
                    </div>
                  </div>
                </div>

                <div className="form-group mb-3">
                  <label className="mb-2">{t('SELECCIONAR COLORES')}</label>
                  <div className="color-picker-btn">
                    {/* 
                    {Object.keys(flagColor).map((flagObj, i) => {
                      return (
                        <div className="colour-pack" key={i}>
                          <span>
                            <img src="./img/x.svg" className="cross-icon"
                              alt="cross-icon" id={flagObj} onClick={handleCloseBox}></img>
                          </span>

                          <input type="color"
                            id="favcolor"
                            name={`theme_color${Object.keys(flagColor).length + 1}`}
                            // value={flagColor?.[`theme_color${i}`] || '#FFFFFF'}
                            // value={flagObj.flagColor || '#FFFFFF'}
                            value={flagColor[flagObj] || '#FFFFFF'}
                            onChange={handleHexColorCode}
                          ></input>
                        </div>
                      )
                    })
                    } */}

                    <div className={`colour-pack ${(colorBoxStatus.theme_color1) && 'hiddenBox'}`} >
                      <span>
                        <img src="./img/x.svg" className="cross-icon"
                          alt="cross-icon" id='theme_color1'
                          onClick={handleCloseBox}></img>
                      </span>
                      <input type="color" id="favcolor"
                        name="theme_color1" value={flagColor.theme_color1 || '#FFFFFF'}
                        onChange={handleHexColorCode}></input>
                    </div>

                    <div className={`colour-pack ${(colorBoxStatus.theme_color2) && 'hiddenBox'}`} >
                      <span>
                        <img src="./img/x.svg" className="cross-icon"
                          alt="cross-icon" id='theme_color2'
                          onClick={handleCloseBox}></img>
                      </span>
                      <input type="color" id="favcolor"
                        name="theme_color2" value={flagColor.theme_color2 || '#FFFFFF'}
                        onChange={handleHexColorCode} />
                    </div>

                    <div className={`colour-pack ${(colorBoxStatus.theme_color3) && 'hiddenBox'}`} >
                      <span>
                        <img src="./img/x.svg" className="cross-icon"
                          alt="cross-icon" id='theme_color3'
                          onClick={handleCloseBox}></img>
                      </span>
                      <input type="color" id="favcolor"
                        name="theme_color3" value={flagColor.theme_color3 || '#FFFFFF'}
                        onChange={handleHexColorCode} />
                    </div>

                    {(colorBoxStatus.theme_color3)
                      && (<span className="plus-icon" onClick={handleBoxShow}>
                        <img src="./img/plus.svg" alt="append-icon" className="plus-icon"
                        // onClick={handleCloseBox} 
                        />
                      </span>
                      )
                    }
                  </div>
                </div>

                <div className="form-group">
                  {/* <label className="mb-2">AGREGA DIRIGENTES DIGITALES</label> */}
                  <label className="mb-2"></label>
                  <div className='footer-sec'>

                    <div className='footer-flag'>
                      <div className='flag-img'>
                        <img src={(previewShield || shieldData?.shield_logo)
                          || selectedTeam?.team_logo_url} alt="footer-logo" />
                      </div>
                      <div className='flag-colors'>
                        <span className='grey-color'
                          style={{ backgroundColor: flagColor.theme_color1 }}>
                        </span>
                        <span className='white-color'
                          style={{ backgroundColor: flagColor.theme_color2 }}>
                        </span>
                        <span className='grey-color'
                          style={{
                            backgroundColor: flagColor.theme_color3
                              || flagColor.theme_color1
                          }}>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

              </Modal.Body>

              <Modal.Footer className="border-0">
                <div className="form-group text-center">
                  <button className="green-btn mb-0" onClick={hanldeSaveFlagColor}>{t('Guardar')}</button>
                  <button className="red-btn mb-0" onClick={handleClose}>{t('Cancelar')}</button>
                </div>
              </Modal.Footer>

            </Modal>

            <Modal show={showAmbassador} className="club-popup" onHide={handleCloseAmbassodor}>
              <Modal.Body >
                <div className="form-group selection-input mb-30 ">
                  <label className="mb-2">{t('SELECCIONAR PAÍS')}</label>
                  {/* <input className="text-white" type="text" name="" id="" /> */}

                  <input type="text" list="country"
                    className="text-white club-selection"
                    name='country_code'
                    placeholder={t("seleccione el nombre del país!")}
                    onChange={handleAmbassadorCountry}
                    onClick={(e) => e.target.select(e)} />

                  <datalist id="country">
                    {allCountry?.map((member, key) => {
                      return <option key={key} id={member.code} value={member.name} />
                    }
                    )}
                  </datalist>
                </div>

                <div className="upper-ambassadore-box ">
                  <div className="male-ambassadore-box flex-grow-1 p-2">
                    <label className="mb-2">{t('SUBIR EMBAJADOR MASCULINO')}</label>

                    <div className="select-clrImg">
                      <div className="color-picker-btn">
                        <div className='img-edit'>
                          <GrUpload />
                          <input id="uploadFile" name="male" onChange={handleAmbassadorSelect}
                            type="file" className="upload" readOnly />
                        </div>
                      </div>

                      <div className="select-clrName">
                        <input
                          type="text"
                          name="male"
                          value={(newMaleAmbass || maleAmbassador?.image_url) || ""}
                          readOnly
                          className="text-white club-selection" />
                      </div>
                    </div>

                    <div className="outer-ambassador">
                      {previewMaleAmbassador
                        && <span>
                          <img src="./img/x.svg" className="cross-icon-staff ambassador-cross-icon"
                            alt="cross-icon"
                            onClick={handleCloseMaleAmbassadorImg}></img>
                        </span>
                      }
                      {(previewMaleAmbassador || maleAmbassador?.image_url) &&
                        <img src={previewMaleAmbassador || maleAmbassador?.image_url} alt="ambassador-icon"></img>
                      }
                    </div>
                  </div>

                  <div className="female-ambassadore-box flex-grow-1 p-2">
                    <label className="mb-2">{t('SUBIR FEMENINO EMBAJADORA')}</label>

                    <div className="select-clrImg">
                      <div className="color-picker-btn">
                        <div className='img-edit'>
                          <GrUpload />
                          <input id="uploadFile" name="female" onChange={handleAmbassadorSelect}
                            type="file" className="upload" readOnly />
                        </div>
                      </div>

                      <div className="select-clrName">
                        <input
                          type="text"
                          name="female"
                          // onClick={handleClearAmbassador}
                          readOnly
                          value={(newFemaleAmbass || femaleAmbassador?.image_url) || ""}
                          className="text-white club-selection" >

                        </input>
                      </div>
                    </div>

                    <div className="outer-ambassador">
                      {/* {
                        previewAmbassadore
                        && <img src={previewAmbassadore} alt="ambassador-icon"></img>
                      } */}
                      {previewFemaleAmbassador
                        && <div className="staff-profile">
                          <img src="./img/x.svg" className="cross-icon-staff ambassador-cross-icon"
                            alt="cross-icon"
                            onClick={handleCloseFemaleAmbassadorImg}></img>
                        </div>
                      }
                      {(previewFemaleAmbassador || femaleAmbassador?.image_url) &&
                        <img src={previewFemaleAmbassador || femaleAmbassador?.image_url} alt="ambassador-icon"></img>
                      }
                    </div>
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer className="border-0">
                <div className="form-group text-center">
                  <button className="green-btn mb-0" onClick={submitAmbassador}>{t('Guardar')}</button>
                  <button className="red-btn mb-0" onClick={handleCloseAmbassodor}>{t('Cancelar')}</button>
                </div>
              </Modal.Footer>

            </Modal>

            {/* <Modal show={showEditAmbassador} className="club-popup" onHide={handleCloseEditAmbassodor}>
              <Modal.Body >
                <div className="form-group selection-input mb-30 ">
                  <label className="mb-2">{t('SELECCIONAR PAÍS')}</label>

                  <input type="text" list="country"
                    className="text-white club-selection"
                    name='Selected_team'
                    placeholder={t("seleccione el nombre del país!")}
                    onChange={handleAmbassadorCountry} />

                  <datalist id="country">
                    {allCountry?.map((member, key) => {
                      return <option key={key} id={member.code} value={member.name} />
                    }
                    )}
                  </datalist>
                </div>

                <label className="mb-2">{t('SUBIR EMBAJADOR')}</label>
                <select className="theme-selection"
                  name="gender" value={addAmbassador?.gender}
                  onChange={handleAddAmbassador}>

                  <option>{t('Seleccione género')}</option>
                  <option value="male">{t('Male')}</option>
                  <option value="female">{t('Female')}</option>
                </select>

                <div className="select-clrImg">
                  <div className="color-picker-btn">
                    <div className='img-edit'>
                      <GrUpload />
                      <input id="uploadFile" onChange={handleAmbassadorSelect}
                        type="file" className="upload" readOnly />
                    </div>
                  </div>

                  <div className="select-clrName">
                    <input
                      type="text"
                      value={addAmbassador?.ambassadore_file}
                      className="text-white club-selection" />
                  </div>
                </div>

                <div className="outer-ambassador">
                  {
                    previewAmbassadore
                    && <img src={previewAmbassadore} alt="ambassador-icon"></img>
                  }
                </div>

              </Modal.Body>

              <Modal.Footer className="border-0">
                <div className="form-group text-center">
                  <button className="green-btn mb-0" onClick={submitAmbassador}>{t('Guardar')}</button>
                  <button className="red-btn mb-0" onClick={handleCloseEditAmbassodor}>{t('Cancelar')}</button>
                </div>
              </Modal.Footer>

            </Modal> */}

          </Container>
        </div>
      </div>
    </>
  );
};

export default HomePage;