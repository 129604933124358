import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
// import { MESS_STAGE, FANCY } from "../../BaseURL";
import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FiSearch } from "react-icons/fi";
import Modal from 'react-bootstrap/Modal';
import { GrUpload } from "react-icons/gr";
import GlobalURL from "../../BaseURL";
import Swal from "sweetalert2";
import "./style.css";

import { useSelector } from 'react-redux';
import translations from '../../../locals/translation';

const TeamAdministration = () => {
  const language = useSelector(state => state.language.value);
  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState(false);
  const [playerData, setPlayerData] = useState();
  const [youthPlayerData, setYouthPlayerData] = useState();
  const [otherPlayerData, setOtherPlayerData] = useState();
  const [staffData, setStaffData] = useState();
  const [allTeam, setAllTeam] = useState([]);
  const [youthData, setYouthData] = useState();
  const [currentTab, setCurrentTab] = useState("Jugadores");
  const [otherSelection, setOtherSelection] = useState();
  const [menuItem, setMenuItem] = useState('');
  const [category, setCategory] = useState('Info / Noticias');
  const [displayMenu, setDisplayMenu] = useState(false);
  const [partidosStatus, setPartidosStatus] = useState(false);
  const [btntxt, setBtntxt] = useState('Agregar Noticias');
  const [matchData, setMatchData] = useState({});
  const [youthPartido, setYouthPartido] = useState();
  const [otherPartido, setOtherPartido] = useState();
  const [genderSelected, setGenderSelected] = useState("male");
  const [playerGenderToggle, setPlayerGenderToggle] = useState(false);
  const [allYouthClassification, setAllYouthClassification] = useState();
  const [allOtherClassification, setAllOtherClassification] = useState();
  const [loadYouthClass, setLoadYouthClass] = useState(false);
  const [playerTeamShield, setPlayerTeamShield] = useState();
  // const [loadOtherClass, setLoadOtherClass] = useState(false);

  const [youthCompetencia, setYouthCompentencia] = useState();
  const [otherCompetencia, setOtherCompentencia] = useState();

  // eslint-disable-next-line 
  const [addYouthCompetenciaClass, setAddYouthCompetenciaClass] = useState(false);
  const [editarCompetenciaYouth, setEditarCompetenciaYouth] = useState(false);
  const [editarCompetenciaOther, setEditarCompetenciaOther] = useState(false);
  // const [toggleButton, setToggleButton] = useState(true);

  const handleCloseCompYouth = () => {
    setEditarCompetenciaYouth();
  }

  const handleCloseCompOther = () => {
    setEditarCompetenciaOther();
  }

  const handleYouthCompetencia = (e) => {
    setYouthCompentencia({ ...youthCompetencia, [e.target.name]: e.target.value });
  }

  const handleOtherCompetencia = (e) => {
    setOtherCompentencia({ ...otherCompetencia, [e.target.name]: e.target.value });
  }

  const handleAddYouthCompetencia = () => {
    console.log("add youth competencia", youthCompetencia);
    // setAddYouthCompetenciaClass()
  }

  const handleAddOtherCompetencia = () => {
    console.log("add other competencia", otherCompetencia);
    // setAddYouthCompetenciaClass()
  }

  // const [previousURL, setPreviousURL] = useState();
  const [addYouthClass, setAddYouthClass] = useState(false);
  const [delBtn, setDelBtn] = useState(false);
  const [logo, setLogo] = useState();
  const [addWait, setAddWait] = useState(false);
  const [staffDetails, setStaffDetails] = useState({
    "team_id": localStorage.getItem("team_id")
  });

  const [addStaffForm, setAddStaffForm] = useState(false);
  const [editorEquipo, setEditorEquipo] = useState(false);
  const [editorOther, setEditorOther] = useState(false);
  const [youthLeauge, setYouthLeauge] = useState();


  const [nationalStatus] = useState(JSON.parse(localStorage.getItem("national")));

  const [juvenilesClassification, setJuvenilesClassification] = useState({
    team1_id: localStorage.getItem("team_id"),
    team1_logo: localStorage.getItem("flag"),
    team1_name: localStorage.getItem("team_name"),
    team2_id: "",
    points: "",
    matches_played: "",
    won_matches: "",
    draw_matches: "",
    lost_matches: "",
    season_id: "",
    goal_favour: "",
    goal_against: "",
    goal_difference: "",
    tournament_type: "leauge",
    category: "",
    sub_category: "",
    jornada: "1"
  });

  const [otherClassification, setOtherClassification] = useState({
    team1_id: localStorage.getItem("team_id"),
    team1_logo: localStorage.getItem("flag"),
    team1_name: localStorage.getItem("team_name"),
    team2_id: "",
    points: "",
    matches_played: "",
    won_matches: "",
    draw_matches: "",
    lost_matches: "",
    season_id: "",
    goal_favour: "",
    goal_against: "",
    goal_difference: "",
    tournament_type: "leauge",
    category: "",
    sub_category: "",
    jornada: "1"
  });

  const [seasonsData, setSeasonsData] = useState();
  const [resultados, setResultados] = useState(false);
  const [displayOtrasSelecion, setDisplayOtrasSelecion] = useState();

  const handleShowResultados = () => {
    setResultados(true);
  }

  const handleCloseResultados = () => {
    setResultados()
  }

  const handleCloseEquipo = () => {
    setEditorEquipo()
  }

  const handleCloseOther = () => {
    setEditorOther();
  }

  const handleClose = () => {
    setStaffDetails({
      "team_id": localStorage.getItem("team_id")
    });
    setFile(null);
    setProfile(null);
    setAddStaffForm(false);
  };

  const handleShow = () => setAddStaffForm(true);
  const handleShowEquipo = () => setEditorEquipo(true);
  const handleShowOther = () => setEditorOther(true);

  const [otherSelectionCompetencia, setOtherSelectionCompetencia] = useState();

  const [profile, setProfile] = useState();
  const [file, setFile] = useState(null);
  const payload = new FormData();

  const monthsList = ["enero", "febrero", "marzo", "abril", "mayo", "junio",
    "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];

  const [URLSearchParam, setURLSearchParam] = useSearchParams();

  // console.log('staffDetails: ', staffDetails);

  useEffect(() => {
    let currentSelectedTab = URLSearchParam.get("administration");
    console.log("current tab: in useeffect ", currentSelectedTab);

    if (currentSelectedTab === 'Juveniles') {
      setCurrentTab(currentSelectedTab);
      setMenuItem(nationalStatus ? 'Sub 23' : 'reserve');
      setDisplayMenu(true);
      if (URLSearchParam.get("menu_item"))
        setCategory(URLSearchParam.get("menu_item"));
      else
        setCategory("Info / Noticias");
    } else if (currentSelectedTab === 'otras-selecciones') {
      setCurrentTab(currentSelectedTab);
      setMenuItem('Senior');
      setDisplayMenu(true);
      setCategory(URLSearchParam.get("menu_item"));
      if (URLSearchParam.get("menu_item"))
        setCategory(URLSearchParam.get("menu_item"));
      else
        setCategory("Info / Noticias");
    } else {
      setCurrentTab(currentSelectedTab || 'Jugadores');
    }

    // if(currentSelectedTab==="Juveniles"){
    //   if(URLSearchParam.get("menu_item"))
    //     setCategory(URLSearchParam.get("menu_item"))
    // }else if(currentSelectedTab==="otras-selecciones")
    // setDisplayMenu((currentSelectedTab === 'Juveniles' || currentSelectedTab === "otras-selecciones") ? true : false)

    // eslint-disable-next-line
  }, [])

  const handleMatchData = (e) => {
    setMatchData({ ...matchData, [e.target.name]: e.target.value });
  }

  const handleSelectedGender = (e) => {
    console.log("gender status:  ", e.target.value);
    console.log("selection: ", genderSelected);
    if (genderSelected)
      setGenderSelected(() => "female");
    else
      setGenderSelected(() => "male");
    setPlayerGenderToggle((prevGenderState) => !prevGenderState);
  }

  const handleJuvenilesClassification = (e) => {
    setJuvenilesClassification({ ...juvenilesClassification, [e.target.name]: e.target.value });
  }

  const handleOtherClassification = (e) => {
    setOtherClassification({ ...otherClassification, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    setPlayerGenderToggle(false);
    setGenderSelected(() => "male");
    // setDisplayOtrasSelecion('')
  }, [currentTab])

  //general check of team seletion
  useEffect(() => {
    let teamStatus = localStorage.getItem("team_id");
    if (!teamStatus) {
      Swal.fire(t("EQUIPO!"), t("¡Selecciona el equipo primero!"), "info");
      navigate("/homepage");
    }
    // eslint-disable-next-line
  }, [])

  //handle side menu activation in juveniles & other selection.
  // also handle the drop down menu selection.
  const handleMenu = (key) => {
    setCurrentTab(key);
    setURLSearchParam({ "administration": key });

    setMenuItem(key === 'Juveniles' ? (nationalStatus ? 'Sub 23' : 'reserve') : 'Senior');
    if (key === 'Juveniles' || key === 'otras-selecciones')
      setDisplayMenu(true);
    else
      setDisplayMenu(false);
  }

  //get all general player of team
  useEffect(() => {
    if (currentTab === "Jugadores") {
      //STERLING
      fetch(`${GlobalURL}/api/dev/team_moderator/getAllPlayerByTeam`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({ team_id: localStorage.getItem("team_id"), gender: genderSelected })
      })
        .then(async (response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            setErrMsg(true)
          }
        })
        .then(res => {
          setPlayerTeamShield(localStorage.getItem("flag"))
          setPlayerData(res.data);
        })
        .catch((err) => {
          console.log("error occured: ", err);
          setErrMsg(true);
        });
    }
    // eslint-disable-next-line
  }, [currentTab, playerGenderToggle])

  //get all team
  useEffect(() => {
    fetch(`${GlobalURL}/api/team/getTeamsForTM`, {
      method: "GET",
      mode: "cors",
      headers: {
        "ngrok-skip-browser-warning": "69420",
      }
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          console.log("status: ", response);
        }
      }).then(res => {
        setAllTeam(res.data);
        // console.log('res.datpa: ', res.data);
      })
      .catch((err) => {
        console.log("error occured: ", err);
      });
  }, [])

  //get team id
  const getTeamId = (e) => {
    let id, url, name;
    for (let item of allTeam) {
      if (item.team_name === e.target.value) {
        id = item.team_id;
        url = item.team_logo_url;
        name = item.team_name;
        break;
      }
    }
    // setCountryId(id);
    setMatchData({ ...matchData, [e.target.name]: id });
    setLogo({ ...logo, [e.target.name]: url, [`${e.target.name}_name`]: name });
  }

  //get all youth players
  useEffect(() => {
    if (menuItem && currentTab === 'Juveniles') {
      fetch(`${GlobalURL}/api/dev/team_moderator/getPlayerListByCategory`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({ team_id: localStorage.getItem("team_id"), category: menuItem })
      })
        .then(async (response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            setErrMsg(true)
          }
        })
        .then(res => {
          setYouthPlayerData(res.data);
        })
        .catch((err) => {
          console.log("error occured: ", err);
          setErrMsg(true);
        });
    }
    else if (menuItem && currentTab === 'otras-selecciones') {
      fetch(`${GlobalURL}/api/dev/team_moderator/getPlayerListByCategory`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({ team_id: localStorage.getItem("team_id"), category: menuItem })
      })
        .then(async (response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            setErrMsg(true)
          }
        })
        .then(res => {
          setOtherPlayerData(res.data);
        })
        .catch((err) => {
          console.log("error occured: ", err);
          setErrMsg(true);
        });
    }
  }, [menuItem, currentTab])

  //get all the team staff & coaches.
  const handleGetStaffDetails = () => {
    // fetch(`${FANCY}/api/dev/team_moderator/getTeamStaffDetails`, {
    fetch(`${GlobalURL}/api/dev/besoccer/getTeamStaffByteamid`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
        "ngrok-skip-browser-warning": "69420",
      },
      body: JSON.stringify({ team_id: localStorage.getItem("team_id"), gender: genderSelected })
    })
      .then(async (response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          setErrMsg(true);
        }
      })
      .then((res) => {
        setStaffData(res.data)
        // console.log('res.data::: ', res.data);
      })
      .catch((err) => {
        // console.log("error occured: ", err);
        setErrMsg(true);
      });
  }

  // handle staff details
  useEffect(() => {
    if (currentTab === "cuerpo-técnico")
      handleGetStaffDetails();
    // eslint-disable-next-line
  }, [currentTab, genderSelected])

  //get youth partidos list.
  const youthPartidiosList = () => {
    if (currentTab === 'Juveniles' && category === 'Partidos') {
      fetch(`${GlobalURL}/api/dev/team_moderator/getFixtureByCategory`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({
          team_id: localStorage.getItem("team_id"),
          category: "youth",
          sub_category: menuItem,
          // leauge: youthSelectionCompetencia
        })
      })
        .then(async (response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            setErrMsg(true)
          }
        })
        .then(res => {
          // let respData = res.data.reverse();
          // setYouthPartido(respData);
          setYouthPartido(res.data);
        })
        .catch((err) => {
          console.log("error occured: ", err);
          setErrMsg(true);
        });
    }
    else if (currentTab === 'otras-selecciones' && category === 'Partidos') {
      fetch(`${GlobalURL}/api/dev/team_moderator/getFixtureByCategory`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({
          team_id: localStorage.getItem("team_id"),
          category: "other-selection",
          sub_category: menuItem,
          leauge: otherSelectionCompetencia
        })
      })
        .then(async (response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            setErrMsg(true)
          }
        })
        .then(res => {
          // let respData = res.data.reverse();
          // setOtherPartido(respData);
          setOtherPartido(res.data);
        })
        .catch((err) => {
          console.log("error occured: ", err);
          setErrMsg(true);
        });
    }
  }

  //fetch youth partidos list (useEffect)
  useEffect(() => {
    youthPartidiosList();
    // eslint-disable-next-line
  }, [currentTab, category, menuItem, otherSelectionCompetencia])

  //handle menu item (event key)
  const handleMenuItem = (eventKey, e) => {
    setMenuItem(eventKey);
    console.log("evt: ", e.target.innerText);
    setDisplayOtrasSelecion(e.target.innerText);
  }

  // get youth news
  useEffect(() => {
    if (currentTab === 'Juveniles') {
      if (category === 'Info / Noticias') {
        setBtntxt('Agregar Noticias');
        setYouthData('')
        fetch(`${GlobalURL}/api/dev/team_moderator/getCategoryYouthNews`, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
            "ngrok-skip-browser-warning": "69420",
          },
          body: JSON.stringify({ team_id: localStorage.getItem("team_id"), category: menuItem })
        })
          .then(async (response) => {
            if (response.status === 200) {
              setErrMsg(false);
              return response.json();
            }
            else {
              setErrMsg(true);
            }
          })
          .then(res => {
            setYouthData(res.data);
          })
          .catch((err) => {
            setErrMsg(true);
            console.log("error occured: ", err);
          });
      }
      else if (category === 'Plantel') {
        setBtntxt('Agregar Plantel');
        setYouthData([{ data: "itration" }])
      }
      else if (category === 'Partidos') {
        setBtntxt('Agregar Partidos');
        setYouthData([{ data: "itration" }])
      }
      else if (category === 'Clasificación') {
        setBtntxt('EDITAR COMPETENCIA');
        setYouthData([{ data: "itration" }])
        fetch(`${GlobalURL}/api/dev/besoccer/getSeasonByTeam`, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem("access-tokken"))}`,
            "ngrok-skip-browser-warning": "69420"
          },
          body: JSON.stringify({ "team_id": localStorage.getItem("team_id") })
        })
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              console.log("status: ", response);
            }
          })
          .then((res) => {
            setSeasonsData(res.data);
            console.log('res.data: ', res.data);
            setJuvenilesClassification(
              {
                ...juvenilesClassification,
                season_id: res.data[0].season_id,
                season_year: res.data[0].year
              });
          })
          .catch((err) => {
            console.log("error occurred: ", err);
          })
      }
    }

    else if (currentTab === 'otras-selecciones') {
      if (category === 'Info / Noticias') {
        setBtntxt('Agregar Noticias');
        setOtherSelection('')
        fetch(`${GlobalURL}/api/dev/team_moderator/getAllOtherNews`, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
            "ngrok-skip-browser-warning": "69420",
          },
          body: JSON.stringify({ team_id: localStorage.getItem("team_id"), sub_category: menuItem })
        })
          .then(async (response) => {
            if (response.status === 200) {
              setErrMsg(false);
              return response.json();
            }
            else {
              setErrMsg(true);
            }
          })
          .then(res => {
            setOtherSelection(res.data);
          })
          .catch((err) => {
            setErrMsg(true);
            console.log("error occured: ", err);
          });
      }
      else if (category === 'Plantel') {
        console.log("data: plantel")
        setOtherSelection([{ data: "itration2" }]);
        setBtntxt('Agregar Plantel');
      }
      else if (category === 'Partidos') {
        setOtherSelection([{ data: "itration3" }]);
        setBtntxt('Agregar Partidos');
      }
      else if (category === 'Clasificación') {
        setBtntxt('EDITAR COMPETENCIA');
        setOtherSelection([{ data: "itration" }]);
        fetch(`${GlobalURL}/api/dev/besoccer/getSeasonByTeam`, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem("access-tokken"))}`,
            "ngrok-skip-browser-warning": "69420"
          },
          body: JSON.stringify({ "team_id": localStorage.getItem("team_id") })
        })
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              console.log("status: ", response);
            }
          })
          .then((res) => {
            setSeasonsData(res.data);
            setOtherClassification(
              {
                ...otherClassification,
                season_id: res.data[0].season_id,
                season_year: res.data[0].year
              });
          })
          .catch((err) => {
            console.log("error occurred: ", err);
          })
      }
    }
    // eslint-disable-next-line
  }, [currentTab, category, menuItem])

  // get leauge data
  useEffect(() => {
    if (currentTab === 'Juveniles' && category === 'Partidos') {
      fetch(`${GlobalURL}/api/dev/besoccer/getTeamLeaugesForFixture`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
          "ngrok-skip-browser-warning": "69420",
        },
        // body: JSON.stringify({ team_id: localStorage.getItem("team_id"), category: menuItem })
        body: JSON.stringify({ team_id: "583", category: menuItem })
      })
        .then(async (response) => {
          if (response.status === 200) {
            setErrMsg(false);
            return response.json();
          }
          else {
            setErrMsg(true);
          }
        })
        .then(res => {
          setYouthLeauge(res.data);
          console.log('res.data: ', res.data);
        })
        .catch((err) => {
          setErrMsg(true);
          console.log("error occured: ", err);
        });
    }

    else if (currentTab === 'otras-selecciones' && category === 'Partidos') {
      fetch(`${GlobalURL}/api/dev/besoccer/getTeamLeaugesForFixture`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({ team_id: localStorage.getItem("team_id"), category: menuItem })
      })
        .then(async (response) => {
          if (response.status === 200) {
            setErrMsg(false);
            return response.json();
          }
          else {
            setErrMsg(true);
          }
        })
        .then(res => {
          //need to change in case of leauge
          // setOtherSelectionLeauge(res.data);
        })
        .catch((err) => {
          setErrMsg(true);
          console.log("error occured: ", err);
        });
    }
    // eslint-disable-next-line
  }, [currentTab, category, menuItem])

  //get classification - youth
  useEffect(() => {
    if (currentTab === "Juveniles" && category === "Clasificación") {
      setLoadYouthClass(true);
      fetch(`${GlobalURL}/api/dev/team_moderator/getClassification`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify(
          {
            team_id: localStorage.getItem("team_id"),
            season_id: juvenilesClassification?.season_id,
            tournament_type: juvenilesClassification?.tournament_type,
            jornada: (juvenilesClassification?.jornada || 1),
            sub_category: menuItem,
            category: currentTab,
          })
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json()
          } else {
            setErrMsg(true)
          }
        })
        .then((res) => {
          console.log('res.data: ', res.data);
          setAllYouthClassification(res.data);
        })
        .catch((err) => {
          console.log("error occured: ", err);
          setErrMsg(true);
        })
        .finally(() => {
          setLoadYouthClass(false);
        });
    }
  }, [currentTab, menuItem, category, juvenilesClassification?.season_id,
    juvenilesClassification?.tournament_type, juvenilesClassification?.jornada])

  //get classification - otras-selecciones
  useEffect(() => {
    if (currentTab === "otras-selecciones" && category === "Clasificación") {
      // setLoadOtherClass(true);
      fetch(`${GlobalURL}/api/dev/team_moderator/getClassification`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify(
          {
            team_id: localStorage.getItem("team_id"),
            season_id: juvenilesClassification?.season_id,
            tournament_type: juvenilesClassification?.tournament_type,
            jornada: (juvenilesClassification?.jornada || 1),
            sub_category: menuItem,
            category: currentTab,
          })
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json()
          } else {
            setErrMsg(true)
          }
        })
        .then((res) => {
          console.log('res.data: ', res.data);
          setAllOtherClassification(res.data);
        })
        .catch((err) => {
          console.log("error occured: ", err);
          setErrMsg(true);
        })
      // .finally(() => {
      //   setLoadOtherClass(false);
      // });
    }
  }, [currentTab, menuItem, category, juvenilesClassification?.season_id,
    juvenilesClassification?.tournament_type, juvenilesClassification?.jornada])

  //handle category (news/player/match)
  const handleCategory = (eventKey) => {
    setCategory(eventKey);
    // URLSearchParam.append("menu_item", eventKey);
    // setURLSearchParam((prev)=>({...prev, "menu_item": eventKey}));
    // setURLSearchParam({...URLSearchParam, "menu_item": eventKey});

    // URLSearchParam.append("menuItem",eventKey);
    // console.log('* URLSearchParam: ', {...URLSearchParam});
    setURLSearchParam((prevParams) => {
      const srchParam = new URLSearchParams(prevParams);
      if (srchParam.get("menu_item"))
        srchParam.delete("menu_item");
      srchParam.append("menu_item", eventKey);
      return srchParam.toString();
    })
  }

  //handle navigation on button click
  const handleBtnClick = () => {

    if (currentTab === 'Juveniles') {
      if (category === 'Info / Noticias') {
        navigate('/addyouthnews');
      }
      else if (category === 'Plantel') {
        navigate('/addyouthplayer');
      }
      else if (category === 'Partidos') {
        matchData.category = 'youth';
        // setMenuItem("select sub category");
        // matchData.sub_category = menuItem;
        setPartidosStatus(true);
        handleJuvenilesMatch();
      }
      else if (category === 'Clasificación') {
        console.log("Editar compenticia: in juveniles ");
        setEditarCompetenciaYouth(true);
      }
    }
    else if (currentTab === 'otras-selecciones') {
      if (category === 'Info / Noticias') {
        navigate('/add-otherSelection-news');
      }
      else if (category === 'Plantel') {
        navigate('/addotherplayer');
      }
      else if (category === 'Partidos') {
        matchData.category = 'otras-selecciones';
        setPartidosStatus(true);
        handleOtrasMatch();
      }
      else if (category === 'Clasificación') {
        console.log("Editar compenticia: in otras selecciones ");
        setEditarCompetenciaOther(true);
      }
    }
  }

  const handleJuvenilesMatch = () => {
    if (partidosStatus) {
      console.log("match all_data: ", matchData);

      if (matchData.team1 && matchData.team2
        && matchData.competetion && matchData.referee
        && matchData.stadium && matchData.match_date
        && matchData.match_time && matchData.category
        && matchData.team1_points && matchData.team2_points
        && matchData.category && matchData.status) {
        if (matchData.sub_category === 'select sub category') {
          Swal.fire(t("subcategoría"), t("¡seleccione subcategoría!"), "error");
          return;
        }
        // matchData.sub_category = menuItem;
        fetch(`${GlobalURL}/api/dev/team_moderator/addPlayerFixture`, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem('access-token'))}`,
            "ngrok-skip-browser-warning": "69420",
          },
          body: JSON.stringify({ ...matchData, sub_category: menuItem })
        })
          .then((response) => {
            if (response.status === 200) {
              Swal.fire(t("¡Salvado!"), "", "success");
              handleBackFrPartidos();
              youthPartidiosList();
              setMatchData({});
              setLogo({});
              return response.json();
            }
            else {
              console.log("status: ", response)
              Swal.fire(t("¡Error Interno del Servidor!"), "", "error");
            }
          })
          .catch((err) => {
            console.log("error occured: ", err);
            Swal.fire(t("¡Error Interno del Servidor!"), "", "error");
          });
      }
      else {
        Swal.fire(t("¡Campo requerido!"), "", "error");
      }
    }
  }

  const handleOtrasMatch = () => {

    if (partidosStatus) {
      console.log("match all_data: ", matchData);

      if (matchData.team1 && matchData.team2
        && matchData.competetion && matchData.referee
        && matchData.stadium && matchData.match_date
        && matchData.match_time && matchData.category
        && matchData.team1_points && matchData.team2_points
        && matchData.category && matchData.status) {
        if (matchData.sub_category === 'select sub category') {
          Swal.fire(t("subcategoría"), t("seleccione subcategoría!"), "error");
          return;
        }

        // matchData.sub_category= menuItem;
        fetch(`${GlobalURL}/api/dev/team_moderator/addPlayerFixture`, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem('access-token'))}`,
            "ngrok-skip-browser-warning": "69420",
          },
          body: JSON.stringify({ ...matchData, sub_category: menuItem })
        })
          .then((response) => {
            if (response.status === 200) {
              Swal.fire(t("¡Salvado!"), "", "success");
              handleBackFrPartidos();
              youthPartidiosList();
              setMatchData({});
              setLogo({});
              return response.json();
            }
            else {
              console.log("status: ", response)
              Swal.fire(t("¡Error Interno del Servidor!"), "", "error");
            }
          })
          .catch((err) => {
            console.log("error occured: ", err);
            Swal.fire(t("¡Error Interno del Servidor!"), "", "error");
          });
      }
      else {
        Swal.fire(t("¡Campo requerido!"), "", "error");
      }
    }
  }

  const getDate = (incoming_date) => {
    let dateObject;
    if (incoming_date)
      dateObject = new Date(incoming_date)
    else
      dateObject = new Date(matchData?.match_date);

    // console.log('from getdate: ', JSON.stringify(dateObject));
    if (!JSON.stringify(dateObject)) {
      // console.log('act: ', JSON.stringify(dateObject));
      return '';
    }
    const day = dateObject?.getDate();
    const monthAbbreviation = dateObject?.toLocaleString('es-ES', { month: 'short' });
    const year = dateObject?.getFullYear();

    // console.log('${day} ${monthAbbreviation} ${year}: ', day, monthAbbreviation, year);
    // console.log('isNaN(day): ', isNaN(day));
    if (!isNaN(day))
      return `${day} ${monthAbbreviation} ${year}`;
  }

  const handleBackFrPartidos = () => {
    setPartidosStatus(false);
  }

  //handle add staff - state
  const handleAddStaffData = (e) => {
    setStaffDetails({ ...staffDetails, [e.target.name]: e.target.value });
  }

  //handle add staff
  const handleAddStaff = (e) => {
    e.preventDefault();

    // console.log("payload (before uploaded): ", payload);
    if (Object.keys(staffDetails).length && file) {
      payload.append("media", file);
      // console.log("object: ", file.length);

      for (let item in staffDetails) {
        payload.append(item, staffDetails[item]);
        console.log('staffDetails[payload]: ', staffDetails[item]);
      }
      console.log("payload: ", payload);
      setAddWait(true);
      fetch(`${GlobalURL}/api/dev/team_moderator/addTeamStaff`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem('access-token'))}`,
          "ngrok-skip-browser-warning": "69420",
          // "team_id": localStorage.getItem("team_id")
        },
        body: payload
      }).then((response) => {
        if (response.status === 200) {
          setAddStaffForm(false);
          handleGetStaffDetails();
          Swal.fire(t("¡El personal se agregó exitosamente!"), "success");
          return response.json();
        } else {
          setErrMsg(t('¡Error del Servidor!'));
        }
      })
        .catch(() => {
          setErrMsg(t('¡Error del Servidor!'));
        }).finally(() => {
          // setSubmitBtn(false);
          setAddWait(false);
        });
    } else {
      Swal.fire("", t('¡Campos requeridos!'), "info");
    }
  }

  const handleFileSelect = (e) => {
    // setFile(e.target.files[0]);
    if (e?.target?.files[0]) {
      // setProfile(URL.createObjectURL(e.target.files[0]));
      // setFile(URL.createObjectURL(e?.target?.files[0]));
      setFile(e?.target?.files[0]);
    }
    setStaffDetails({
      ...staffDetails,
      staff_image_url: e.target.value.replace("C:\\fakepath\\", "")
    });
  };

  //handle edit staff
  const handleEditStaff = (e) => {
    let staffGetId = e.target.closest(".personal").id;
    setAddStaffForm(true);

    let allStaffData = [...Object.values(staffData).map(item => item).flat()]
    let clickedObject = allStaffData.find((item) => {
      return Number(item.staff__id) === Number(staffGetId);
    })
    setProfile(clickedObject.staff_image_url);
    setStaffDetails(clickedObject);
  }

  // close staff modal box
  const handleCloseBox = (e) => {
    console.log("target value : ", e.target.id);
    // setStaffDetails({ ...staffDetails, staff_image_url: '' });

    setFile(null);
    if (!e.target.id) {
      setProfile(null);
      setStaffDetails({ ...staffDetails, staff_image_url: '' });
    }
  }

  // handle staff deletion
  const handleStaffDeletion = (id) => {
    console.log("staff-id: ", id);

    Swal.fire({
      title: t("Estas seguro"),
      text: t("¿Quieres borrar?"),
      showDenyButton: true,
      confirmButtonText: t("Borrar"),
      denyButtonText: t("Cancelar")
    }).then((result) => {
      if (result.isConfirmed) {
        setDelBtn(true);
        fetch(`${GlobalURL}/api/dev/team_moderator/deleteStaff`, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem("access-token"))}`,
            "ngrok-skip-browser-warning": "69420"
          },
          body: JSON.stringify({ staff__id: id })

        }).then((response) => {
          if (response.status === 200) {
            handleGetStaffDetails();
            return response.json();
          }
          else {
            Swal.fire("Error", t("¡Error al eliminar personal!"), "error");
          }
        }).then((res) => {
          console.log("res staff section:", res);
          handleClose();
          Swal.fire("Success", t("¡Personal eliminado exitosamente!"), "success");
        }).catch((err) => {
          console.log("error occured: ", err);
          Swal.fire("error", t("¡Error Interno del Servidor!"), "error");
        }).finally(() => {
          setDelBtn(false);
        })
      }
    });
  }

  const handleSelectTeam = (eventKey, e) => {
    // console.log("team: ", eventKey, e);
    let teamData = allTeam.find((team) => {
      return Number(team.team_id) === Number(eventKey);
    });
    // console.log("Team data: ", teamData);
    if (currentTab === "Juveniles") {
      setJuvenilesClassification((prev) => ({
        ...prev,
        team1_logo: teamData.team_logo_url,
        team1_name: teamData.team_name,
        team1_id: teamData.team_id
      })
      )
    } else {
      setOtherClassification((prev) => ({
        ...prev,
        team1_logo: teamData.team_logo_url,
        team1_name: teamData.team_name,
        team1_id: teamData.team_id
      })
      )
    }
  }

  const handleSecondTeam = (e) => {
    console.log("idd: ", e.target.value);
    let teamInfo = allTeam.find((team) => {
      return (team.team_name) === (e.target.value);
    });

    console.log('teamInfo: ', teamInfo);
    if (currentTab === "Juveniles") {
      setJuvenilesClassification((prev) => ({
        ...prev,
        team2_logo: teamInfo?.team_logo_url,
        team2_name: teamInfo?.team_name,
        team2_id: teamInfo?.team_id
      }));
    }
    else {
      setOtherClassification((prev) => ({
        ...prev,
        team2_logo: teamInfo?.team_logo_url,
        team2_name: teamInfo?.team_name,
        team2_id: teamInfo?.team_id
      }));
    }
  }

  const handleSelectTournament = (eventKey) => {
    if (currentTab === "Juveniles") {
      setJuvenilesClassification((prev) => ({
        ...prev,
        tournament_type: eventKey
      }))
    } else {
      setOtherClassification((prev) => ({
        ...prev,
        tournament_type: eventKey
      }))
    }
  }

  const handleSeason = (eventKey) => {
    let seasonInfo = seasonsData?.find((season) => {
      return (Number(season.season_id) === Number(eventKey))
    });

    setJuvenilesClassification((prev) => ({
      ...prev,
      season_id: eventKey,
      season_year: seasonInfo.year
    }))
  }

  const handleJornada = (eventKey) => {
    if (currentTab === "Juveniles") {
      setJuvenilesClassification((prev) => ({ ...prev, jornada: eventKey }));
    } else {
      setOtherClassification((prev) => ({ ...prev, jornada: eventKey }))
    }
  }

  //handle the add youth classification
  const handleAddYouthClassification = () => {
    setAddYouthClass(true);
    fetch(`${GlobalURL}/api/dev/team_moderator/addClassification`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
        "ngrok-skip-browser-warning": "69420",
      },
      body: JSON.stringify({ ...juvenilesClassification, sub_category: menuItem, category: currentTab })
    })
      .then((response) => {
        if (response.status === 200) {
          Swal.fire(t("éxito"), t("clasificación agregada!"), "success");
          handleCloseEquipo();
          return response.json();
        } else {
          setErrMsg(true);
        }
      }).then((resp) => {
        console.log("after save", resp.data);
        setAllYouthClassification([...allYouthClassification, resp.data]);
      })
      .catch((err) => {
        console.log("error occured: ", err);
        setErrMsg(true);
      })
      .finally(() => {
        setAddYouthClass(false);
      });
  }

  //handle the add other selection classification
  const handleAddOtherClassification = () => {
    setAddYouthClass(true);
    fetch(`${GlobalURL}/api/dev/team_moderator/addClassification`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
        "ngrok-skip-browser-warning": "69420",
      },
      body: JSON.stringify({ ...otherClassification, sub_category: menuItem, category: currentTab })
    })
      .then((response) => {
        if (response.status === 200) {
          Swal.fire("success", t("clasificación agregada!"), "success");
          handleCloseOther();
        } else {
          setErrMsg(true)
        }
      })
      .catch((err) => {
        console.log("error occured: ", err);
        setErrMsg(true);
      })
      .finally(() => {
        setAddYouthClass(false);
      });
  }

  const checkColor = (qualification) => {
    console.log('qualification: ', qualification);
    if (qualification?.includes("nonqualify")) {
      return "bg-red";
    }
    if (qualification?.includes("Calificar")) {
      return "bg-green";
    }
    if (qualification?.includes("repechaje")) {
      return "bg-blue";
    }
    else {
      return "bg-yellow";
    }
  }

  const handleCheckStreak = (streakType) => {
    console.log("streakType: ", streakType);
    // if (streakType === 'l') {
    //   console.log("manage: ", streakType);
    //   return "extra"
    // } else if (streakType === 'd') {
    //   return "extra-draw"
    // }

    return streakType === 'l' ? 'extra' : 'extra-draw';
  }

  return (
    <>
      <div className="dashboard">
        <div className="dashboard-container">
          <div className="team-administration-page">
            <Container fluid>
              <Row>

                <Col xs lg="3 ">
                  <div className="admin-menu-sec drop-height">

                    {displayMenu && (
                      <div className="admin-dropdown">

                        <Dropdown onSelect={handleCategory}>
                          <Dropdown.Toggle
                            className="menu-dropdown"
                            id="dropdown-basic"
                          >
                            {category}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item eventKey='Info / Noticias'>
                              {t("Info / Noticias")}
                            </Dropdown.Item>
                            <Dropdown.Item eventKey='Plantel'>
                              {t("Plantel")}
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="Partidos">
                              {t("Partidos")}
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="Clasificación">
                              {t("Clasificación")}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>

                      </div>
                    )}

                  </div>
                </Col>

                <Col xs lg="6">

                  <div className="news-header">
                    <div className="back-sec" onClick={() => navigate('/homepage')} style={{ cursor: "pointer" }} >
                      <img src="./img/volver.png" alt="" />
                    </div>
                    <h3 className="header-title inter-page-header-title">{t("ADMINISTRACIÓN DE EQUIPO")}</h3>
                    <div className="search-icon">
                      <FiSearch />
                    </div>
                  </div>

                  <div className="team-admis-sec legend-tabs equpio-tabs-manage classification-scroll">
                    <Tabs activeKey={currentTab} id="fill-tab-example" onSelect={handleMenu} fill
                    // defaultactivekey="Jugadores" 
                    >

                      <Tab eventKey="Jugadores" title={t("Jugadores")}>
                        <div className="administration-tabs">

                          <div className="radio-btns-sec">
                            <div className="left-radios-sec">
                              <div className="radio-sec">
                                <input
                                  type="radio"
                                  id="test1"
                                  name="radio-group"
                                />
                                <label htmlFor="test1">{t("Cuadrícula")}</label>
                              </div>
                              <div className="radio-sec">
                                <input
                                  type="radio"
                                  id="test2"
                                  name="radio-group"
                                />
                                <label htmlFor="test2">{t("Lista")}</label>
                              </div>
                            </div>

                            <div className="right-radios-sec"
                            // onClick={handleSelectedGender}
                            >

                              <div className="check-box" >
                                <span>{t("Masculino")} </span>
                                <input type="checkbox" name="gender"
                                  value={playerGenderToggle} onClick={handleSelectedGender} />
                                <span>{t("Femenino")}  </span>
                              </div>

                            </div>
                          </div>

                          <div className="team-container">
                            <div className="teampofile-sec forwords-sec">
                              <h4>{t("Portero")}</h4>
                              <Row>
                                {playerData
                                  ? (
                                    ((playerData['goalkeeper']?.length < 1)
                                      && (
                                        <div className="d-flex justify-content-center my-5">
                                          <div className="pt-2 my-5" >
                                            {t("¡No se encontró ningún portero!")}
                                          </div>
                                        </div>
                                      )) ||
                                    playerData['goalkeeper']?.map(item => {
                                      return (
                                        <Col xs lg="2" key={item.player_id}
                                          onClick={() => navigate("/playerprofile", { state: item })}
                                          style={{ cursor: "pointer" }} >
                                          <div className="teamprofile-card" >
                                            <div className="card-header">
                                              <div className="card-profile-img">
                                                <img src={(item?.image_url)
                                                  ? item?.image_url
                                                  : "./img/str4.png"}
                                                  alt="" />
                                              </div>
                                              <p className="team-name">
                                                {item.player_name}
                                              </p>
                                            </div>
                                            <div className="card-bottom">
                                              <span className="red-rank">GK</span>
                                              <span className="team">
                                                {/*
                                                 <img
                                                  src="./img/riverplate.png"
                                                  alt=""
                                                />{" "} 
                                                */}
                                                <img
                                                  src={playerTeamShield}
                                                  alt=""
                                                />{" "}
                                                MCI
                                              </span>
                                            </div>
                                          </div>
                                        </Col>
                                      )
                                    })
                                  )
                                  : (errMsg
                                    ? (
                                      <div className="d-flex justify-content-center my-5">
                                        <div className="pt-2 my-5" >
                                          {t("¡Error Interno del Servidor!")}
                                        </div>
                                      </div>
                                    )
                                    : (
                                      <div className="d-flex justify-content-center my-5 align-items-center">
                                        <div className="spinner-border my-5 " role="status">
                                          <span className="sr-only"></span>
                                        </div>
                                        <div className="pt-2 my-5" >&nbsp;
                                          {t("Cargando")}...
                                        </div>
                                      </div>
                                    )
                                  )
                                }
                              </Row>

                            </div>
                            <div className="teampofile-sec forwords-col team-forword-sec">
                              <h4>{t("Delanteros")}</h4>
                              <Row>
                                {playerData
                                  ? (
                                    ((playerData['attacker']?.length < 1)
                                      && (
                                        <div className="d-flex justify-content-center my-5">
                                          <div className="pt-2 my-5" >
                                            {t("¡No se encontró ningún delantero!")}
                                          </div>
                                        </div>
                                      )) ||
                                    playerData['attacker']?.map((item, i) => {
                                      // console.log("ply team: ", playerData)
                                      return (
                                        <Col xs lg="2" onClick={() => navigate("/playerprofile", { state: item })}
                                          style={{ cursor: "pointer" }} key={i}>
                                          <div className="teamprofile-card" >
                                            <div className="card-header">
                                              <div className="card-profile-img">
                                                <img src={(item?.image_url) ? item?.image_url : "./img/str4.png"}
                                                  alt="" />
                                              </div>
                                              <p className="team-name">
                                                {item.player_name}
                                              </p>
                                            </div>
                                            <div className="card-bottom">
                                              <span className="red-rank">F</span>
                                              <span className="team">
                                                {/* <img
                                                    src="./img/riverplate.png"
                                                    alt=""
                                                  />{" "} */}
                                                <img
                                                  src={playerTeamShield}
                                                  alt=""
                                                />
                                                MCI
                                              </span>
                                            </div>
                                          </div>
                                        </Col>
                                      )
                                    })
                                  )
                                  : (errMsg
                                    ? (
                                      <div className="d-flex justify-content-center my-5">
                                        <div className="pt-2 my-5" >
                                          {t("¡Error Interno del Servidor!")}
                                        </div>
                                      </div>
                                    )
                                    : (
                                      <div className="d-flex justify-content-center my-5 align-items-center">
                                        <div className="spinner-border my-5 " role="status">
                                          <span className="sr-only"></span>
                                        </div>
                                        <div className="pt-2 my-5" >&nbsp;
                                          {t("Cargando")}...
                                        </div>
                                      </div>
                                    )
                                  )
                                }
                              </Row>

                            </div>
                            <div className="teampofile-sec midfielders-sec">
                              <h4>{t("Mediocampista")}</h4>
                              <Row>
                                {playerData
                                  ? (
                                    ((playerData['midfielder'].length < 1)
                                      && (
                                        <div className="d-flex justify-content-center my-5">
                                          <div className="pt-2 my-5" >
                                            {t("¡No se encontraron mediocampistas!")}
                                          </div>
                                        </div>
                                      )) ||
                                    playerData['midfielder']?.map(item => {
                                      return (
                                        <Col xs lg="2" key={item.player_id}>
                                          <div className="teamprofile-card"
                                            onClick={() => navigate("/playerprofile", { state: item })}
                                            style={{ cursor: "pointer" }}>

                                            <div className="card-header">
                                              <div className="card-profile-img">
                                                <img src={(item?.image_url) ? item?.image_url : "./img/str4.png"}
                                                  alt="" />
                                              </div>
                                              <p className="team-name">
                                                {item.player_name}
                                              </p>
                                            </div>
                                            <div className="card-bottom">
                                              <span className="red-rank">M</span>
                                              <span className="team">
                                                {/* <img
                                                  src="./img/riverplate.png"
                                                  alt=""
                                                />{" "} */}
                                                <img
                                                  src={playerTeamShield}
                                                  alt=""
                                                />
                                                MCI
                                              </span>
                                            </div>
                                          </div>
                                        </Col>
                                      )
                                    })
                                  )
                                  : (errMsg
                                    ? (
                                      <div className="d-flex justify-content-center my-5">
                                        <div className="pt-2 my-5" >
                                          {t("¡Error Interno del Servidor!")}
                                        </div>
                                      </div>
                                    )
                                    : (
                                      <div className="d-flex justify-content-center my-5 align-items-center">
                                        <div className="spinner-border my-5 " role="status">
                                          <span className="sr-only"></span>
                                        </div>
                                        <div className="pt-2 my-5" >&nbsp;
                                          {t("Cargando")}...
                                        </div>
                                      </div>
                                    )
                                  )
                                }
                              </Row>

                            </div>
                            <div className="teampofile-sec defensores-sec">
                              <h4>{t("Defensores")}</h4>
                              <Row>
                                {
                                  playerData
                                    ? (
                                      ((playerData['defender'].length < 1)
                                        && (
                                          <div className="d-flex justify-content-center my-5">
                                            <div className="pt-2 my-5" >
                                              {t("¡Ningún defensor encontrado!")}
                                            </div>
                                          </div>
                                        )) ||
                                      playerData['defender']?.map(item => {
                                        return (
                                          <Col xs lg="2" key={item.player_id}>
                                            <div className="teamprofile-card"
                                              onClick={() => navigate("/playerprofile", { state: item })}
                                              style={{ cursor: "pointer" }}>
                                              <div className="card-header">
                                                <div className="card-profile-img">
                                                  <img src={(item?.image_url) ? item?.image_url : "./img/str4.png"} alt="" />
                                                </div>
                                                <p className="team-name">
                                                  {item.player_name}
                                                </p>
                                              </div>
                                              <div className="card-bottom">
                                                <span className="red-rank">D</span>
                                                <span className="team">
                                                  {/* <img
                                                    src="./img/riverplate.png"
                                                    alt=""
                                                  />{" "} */}
                                                  <img
                                                    src={playerTeamShield}
                                                    alt=""
                                                  />
                                                  MCI
                                                </span>
                                              </div>
                                            </div>
                                          </Col>
                                        )
                                      })
                                    )
                                    : (errMsg
                                      ? (
                                        <div className="d-flex justify-content-center my-5">
                                          <div className="pt-2 my-5" >
                                            {t("¡Error Interno del Servidor!")}
                                          </div>
                                        </div>
                                      )
                                      : (
                                        <div className="d-flex justify-content-center my-5 align-items-center">
                                          <div className="spinner-border my-5 " role="status">
                                            <span className="sr-only"></span>
                                          </div>
                                          <div className="pt-2 my-5" >&nbsp;
                                            {t("Cargando")}...
                                          </div>
                                        </div>
                                      )
                                    )
                                }
                              </Row>

                            </div>
                          </div>

                          <div className="center-btn-sec">
                            <button className="green-btn" onClick={() => navigate('/addplayer')}>
                              {t("Agregar Jugador")}
                            </button>
                          </div>

                        </div>
                      </Tab>

                      <Tab eventKey="cuerpo-técnico" title={t("Cuerpo Técnico")}>
                        <div className="administration-tabs technical-staff">

                          <div className="radio-btns-sec">
                            <div className="left-radios-sec">

                              <div className="radio-sec">
                                <input
                                  type="radio"
                                  id="test3"
                                  name="radio-group"
                                />
                                <label htmlFor="test3">{t("Cuadrícula")}</label>
                              </div>

                              <div className="radio-sec">
                                <input
                                  type="radio"
                                  id="test4"
                                  name="radio-group"
                                />
                                <label htmlFor="test4">{t("Lista")}</label>
                              </div>
                              
                            </div>
                            <div className="right-radios-sec"
                            // onClick={handleSelectedGender}
                            >

                              <div className="check-box">
                                <span>{t("Masculino")} </span>
                                <input type="checkbox" name="gender"
                                  value={genderSelected} onClick={handleSelectedGender} />
                                <span>{t("Femenino")}  </span>
                              </div>

                            </div>
                          </div>

                          <div className="team-container">
                            <div className="teampofile-sec forwords-sec">
                              <h4>{t("Cuerpo Técnico")}</h4>
                              <Row>
                                {(staffData)
                                  ? (staffData['curepo_tecnico'].length
                                    ? (staffData['curepo_tecnico']?.map((item, i) => {
                                      return (<Col xs lg="2" key={i}>
                                        <div className="teamprofile-card personal" id={item.staff__id}
                                          onClick={handleEditStaff}>
                                          <div className="card-header">
                                            <div className="card-profile-img">
                                              <img src={item?.staff_image_url || ''} alt="" />
                                            </div>
                                            <p className="team-name">
                                              {item?.staff_name}
                                            </p>
                                          </div>
                                          <div className="card-bottom">
                                            <span className="team">
                                              {localStorage.getItem("team_name") || 'MCI'}
                                            </span>
                                          </div>
                                        </div>
                                      </Col>)
                                    })
                                    )
                                    : (<div className="d-flex justify-content-center my-5">
                                      <div className="pt-2 my-5" >
                                        {t("¡No se encontró personal técnico!")}
                                      </div>
                                    </div>
                                    ))
                                  : (
                                    <div className="Loader d-flex justify-content-center mx-auto align-items-center">
                                      {errMsg
                                        ? (
                                          <div className="text-danger">
                                            {t("¡Error Interno del Servidor!")}
                                          </div>
                                        )
                                        : (
                                          <>
                                            <div className="spinner-border m-5" role="status">
                                              <span className="sr-only"></span>
                                            </div>
                                            <div className=" my-5" > &nbsp;
                                              {t("Cargando")}...
                                            </div>
                                          </>
                                        )
                                      }
                                    </div>
                                  )
                                }
                              </Row>
                            </div>
                            <div className="teampofile-sec midfielders-sec">
                              <h4>{t("Gestión")}</h4>
                              <Row>
                                {staffData
                                  ? (staffData['gestion'].length
                                    ? (staffData['gestion']?.map((item, i) => {
                                      return (<Col xs lg="2" key={i}>
                                        <div className="teamprofile-card personal" key={i}
                                          id={item.staff__id} onClick={handleEditStaff}>
                                          <div className="card-header">
                                            <div className="card-profile-img">
                                              <img src={
                                                item?.staff_image_url || ''
                                              } alt="" />
                                            </div>
                                            <p className="team-name">
                                              {item?.staff_name}
                                            </p>
                                          </div>
                                          <div className="card-bottom">
                                            <span className="team">
                                              {localStorage.getItem("team_name") || 'MCI'}
                                            </span>
                                          </div>
                                        </div>
                                      </Col>)
                                    })
                                    )
                                    : (<div className="d-flex justify-content-center my-5">
                                      <div className="pt-2 my-5" >
                                        {t("¡No se encontró personal administrativo!")}
                                      </div>
                                    </div>
                                    ))
                                  : (
                                    <div className="Loader d-flex justify-content-center mx-auto align-items-center">
                                      {errMsg
                                        ? (
                                          <div className="text-danger">
                                            {t("¡Error Interno del Servidor!")}
                                          </div>
                                        )
                                        : (
                                          <>
                                            <div className="spinner-border m-5" role="status">
                                              <span className="sr-only"></span>
                                            </div>
                                            <div className=" my-5" > &nbsp;
                                              {("Cargando")}...
                                            </div>
                                          </>
                                        )
                                      }
                                    </div>
                                  )
                                }
                              </Row>

                            </div>
                            <div className="teampofile-sec defensores-sec">
                              <h4>{t("Departamentos de Scouting")}</h4>
                              <Row>
                                {staffData
                                  ? (staffData['depto_scouting'].length
                                    ? (staffData['depto_scouting']?.map((item, i) => {
                                      return (<Col xs lg="2" key={i}>
                                        <div className="teamprofile-card personal"
                                          onClick={handleEditStaff} id={item.staff__id}>
                                          <div className="card-header">
                                            <div className="card-profile-img">
                                              <img src={item?.staff_image_url || ''} alt="" />
                                            </div>
                                            <p className="team-name">
                                              {item?.staff_name}
                                            </p>
                                          </div>
                                          <div className="card-bottom">
                                            <span className="team">{localStorage.getItem("team_name") || 'MCI'}</span>
                                          </div>
                                        </div>
                                      </Col>)
                                    })
                                    )
                                    : (<div className="d-flex justify-content-center my-5">
                                      <div className="pt-2 my-5" >
                                        {t("¡No se encontraron departamentos de exploración!")}
                                      </div>
                                    </div>
                                    ))
                                  : (
                                    <div className="Loader d-flex justify-content-center mx-auto align-items-center">
                                      {errMsg
                                        ? (
                                          <div className="text-danger">
                                            {t("¡Error Interno del Servidor!")}
                                          </div>
                                        )
                                        : (
                                          <>
                                            <div className="spinner-border m-5" role="status">
                                              <span className="sr-only"></span>
                                            </div>
                                            <div className=" my-5" > &nbsp;
                                              {t("Cargando")}...
                                            </div>
                                          </>
                                        )
                                      }
                                    </div>
                                  )
                                }
                              </Row>

                            </div>
                            <div className="teampofile-sec defensores-sec">
                              <h4 >{t("Departamentos de médico")}</h4>
                              <Row>
                                {staffData
                                  ? (staffData['depto_medico'].length
                                    ? (staffData['depto_medico']?.map((item, i) => {
                                      return (<Col xs lg="2" key={i}>
                                        <div className="teamprofile-card personal"
                                          onClick={handleEditStaff} id={item.staff__id}>
                                          <div className="card-header">
                                            <div className="card-profile-img">
                                              <img src={
                                                item?.staff_image_url || ''
                                              } alt="" />
                                            </div>
                                            <p className="team-name">
                                              {item?.staff_name}
                                            </p>
                                          </div>
                                          <div className="card-bottom">
                                            <span className="team">
                                              {localStorage.getItem("team_name") || 'MCI'}
                                            </span>
                                          </div>
                                        </div>
                                      </Col>)
                                    })
                                    ) : (<div className="d-flex justify-content-center my-5">
                                      <div className="pt-2 my-5" >
                                        {t("¡No se encontraron departamentos médicos!")}
                                      </div>
                                    </div>
                                    ))
                                  : (
                                    <div className="Loader d-flex justify-content-center mx-auto align-items-center">
                                      {errMsg
                                        ? (
                                          <div className="text-danger">
                                            {t('¡Error Interno del Servidor!')}
                                          </div>
                                        )
                                        : (
                                          <>
                                            <div className="spinner-border m-5" role="status">
                                              <span className="sr-only"></span>
                                            </div>
                                            <div className=" my-5" > &nbsp;
                                              {t("Cargando")}...
                                            </div>
                                          </>
                                        )
                                      }
                                    </div>
                                  )
                                }
                              </Row>

                            </div>

                          </div>

                          <div className="center-btn-sec">
                            <button className="green-btn" onClick={handleShow}>
                              {t("Agregar")}
                            </button>
                          </div>
                        </div>
                      </Tab>

                      <Tab eventKey="Juveniles" title={t("Juveniles")}>

                        <div className="radio-btns-sec">

                          <div className="left-radios-sec">
                            <div className="radio-sec">
                              <input
                                type="radio"
                                id="test5"
                                name="radio-group"
                              />
                              <label htmlFor="test5">{t("Cuadrícula")}</label>
                            </div>
                            <div className="radio-sec">
                              <input
                                type="radio"
                                id="test6"
                                name="radio-group"
                              />
                              <label htmlFor="test6">{t("Lista")}</label>
                            </div>
                          </div>

                          <div className="custom-dropdown-btn-wrap">
                            {console.log('nationalStatus: ', typeof nationalStatus)}
                            {nationalStatus
                              ? (<div className="categories-dropdown">
                                <Dropdown onSelect={handleMenuItem}>
                                  <Dropdown.Toggle
                                    className="dropdown-btn"
                                    id="dropdown-basic"
                                    defaultactivekey='Sub 23'
                                    active={menuItem}>
                                    {menuItem}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item eventKey='Sub 23'>
                                      Sub 23
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey='Sub 20'>
                                      Sub 20
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey='Sub 17'>
                                      Sub 17
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey='Sub 15'>
                                      Sub 15
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>)
                              : (<div className="categories-dropdown">
                                <Dropdown onSelect={handleMenuItem}>
                                  <Dropdown.Toggle
                                    className="dropdown-btn"
                                    id="dropdown-basic"
                                    defaultactivekey='reserve'
                                    active={menuItem}>
                                    {menuItem}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item eventKey='reserve'>
                                      Reserva
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey='fourth'>
                                      Cuarta
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey='fifth'>
                                      Quinta
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey='sixth'>
                                      Sexta
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey='seventh'>
                                      Séptima
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey='eighth'>
                                      Eighth
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey='ninth'>
                                      Novena
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>)
                            }

                            {/* <button className="blue-btn custom-blue-btn">EDITAR NOMBRE</button> */}
                          </div>

                          <div className="right-radios-sec">
                            <div className="check-box">
                              <span>{t("Masculino")}</span>
                              <input type="checkbox" />
                              <span>{t("Femenino")}</span>
                            </div>
                          </div>
                        </div>

                        <div className="juveniles-sec">
                          <div className="juveniles-sec-container scrollbar-plantel">
                            <Row>
                              {youthData
                                ? (youthData?.length
                                  ? (<>
                                    {youthData?.map((item, i) => {
                                      if (category === 'Info / Noticias') {
                                        return (
                                          <>
                                            <Col xs lg="4" key={i}
                                              onClick={() => navigate('/edityouthnews', { state: item })}>
                                              <div className="teampofile-outer h-auto">
                                                <div className="juveniles-card news-card">
                                                  <div className="card-heading">
                                                    <p>{item?.title}</p>
                                                  </div>
                                                  <div className="card-img-sec">
                                                    <img src={(item?.news_media)
                                                      ? (item?.news_media[0]?.url)
                                                      : ("./img/blankimg.png")
                                                    }
                                                      alt="" />
                                                  </div>
                                                </div>
                                              </div>
                                            </Col>
                                          </>
                                        )
                                      }
                                      else if (category === 'Plantel') {
                                        return (
                                          <>
                                            <div className="teampofile-outer" key={i}>

                                              <div className="teampofile-sec forwords-sec forwords-col">
                                                <h4>{t("Portero")}</h4>
                                                <Row>
                                                  {
                                                    youthPlayerData
                                                      ? (
                                                        ((youthPlayerData['goalkeeper'].length < 1)
                                                          && (
                                                            <div className="d-flex justify-content-center my-5">
                                                              <div className="pt-2 my-5" >
                                                                {t("¡No se encontró ningún delantero!")}
                                                              </div>
                                                            </div>
                                                          )) ||
                                                        youthPlayerData['goalkeeper']?.map(item => {
                                                          return (
                                                            <Col xs lg="2" onClick={() => navigate("/playerprofile", { state: item })}
                                                              style={{ cursor: "pointer" }} >
                                                              <div className="teamprofile-card" >
                                                                <div className="card-header">
                                                                  <div className="card-profile-img">
                                                                    <img src={(item?.image_url) ? item?.image_url : "./img/str4.png"} alt="" />
                                                                  </div>
                                                                  <p className="team-name">
                                                                    {item.player_name}
                                                                  </p>
                                                                </div>
                                                                <div className="card-bottom">
                                                                  <span className="red-rank">F</span>
                                                                  <span className="team">
                                                                    <img
                                                                      src="./img/riverplate.png"
                                                                      alt=""
                                                                    />{" "}
                                                                    MCI
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </Col>
                                                          )
                                                        })
                                                      )
                                                      : (errMsg
                                                        ? (
                                                          <div className="d-flex justify-content-center my-5 align-items-center">
                                                            <div className="pt-2 my-5" >
                                                              {t("¡Error Interno del Servidor!")}
                                                            </div>
                                                          </div>
                                                        )
                                                        : (
                                                          <div className="d-flex justify-content-center my-5">
                                                            <div className="spinner-border my-5 " role="status">
                                                              <span className="sr-only"></span>
                                                            </div>
                                                            <div className="pt-2 my-5" >&nbsp;
                                                              {t("Cargando")}...
                                                            </div>
                                                          </div>
                                                        )
                                                      )
                                                  }
                                                </Row>
                                              </div>

                                              <div className="teampofile-sec forwords-sec">
                                                <h4>Delanteros</h4>
                                                <Row>
                                                  {youthPlayerData
                                                    ? (
                                                      ((youthPlayerData['attacker'].length < 1)
                                                        && (
                                                          <div className="d-flex justify-content-center my-5">
                                                            <div className="pt-2 my-5" >
                                                              {t("¡No se encontró ningún portero!")}
                                                            </div>
                                                          </div>
                                                        )) ||
                                                      youthPlayerData['attacker']?.map(item => {
                                                        return (
                                                          <Col xs lg="2" onClick={() => navigate("/playerprofile", { state: item })} style={{ cursor: "pointer" }} >
                                                            <div className="teamprofile-card" >
                                                              <div className="card-header">
                                                                <div className="card-profile-img">
                                                                  <img src={(item?.image_url) ? item?.image_url : "./img/str4.png"} alt="" />
                                                                </div>
                                                                <p className="team-name">
                                                                  {item.player_name}
                                                                </p>
                                                              </div>
                                                              <div className="card-bottom">
                                                                <span className="red-rank">F</span>
                                                                <span className="team">
                                                                  <img
                                                                    src="./img/riverplate.png"
                                                                    alt=""
                                                                  />{" "}
                                                                  MCI
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </Col>
                                                        )
                                                      })
                                                    )
                                                    : (errMsg
                                                      ? (
                                                        <div className="d-flex justify-content-center my-5">
                                                          <div className="pt-2 my-5" >
                                                            {t("¡Error Interno del Servidor!")}
                                                          </div>
                                                        </div>
                                                      )
                                                      : (
                                                        <div className="d-flex justify-content-center my-5 align-items-center">
                                                          <div className="spinner-border my-5 " role="status">
                                                            <span className="sr-only"></span>
                                                          </div>
                                                          <div className="pt-2 my-5" >&nbsp;
                                                            {t("Cargando")}...
                                                          </div>
                                                        </div>
                                                      )
                                                    )
                                                  }
                                                </Row>
                                              </div>

                                              <div className="teampofile-sec midfielders-sec">
                                                <h4>{t("Mediocampista")}</h4>
                                                <Row>
                                                  {
                                                    youthPlayerData
                                                      ? (
                                                        ((youthPlayerData['midfielder'].length < 1)
                                                          && (
                                                            <div className="d-flex justify-content-center my-5">
                                                              <div className="pt-2 my-5" >
                                                                {t("¡No se encontraron mediocampistas!")}
                                                              </div>
                                                            </div>
                                                          ))
                                                        ||
                                                        youthPlayerData['midfielder']?.map(item => {
                                                          return (
                                                            <Col xs lg="2">
                                                              <div className="teamprofile-card" style={{ cursor: "pointer" }}
                                                                onClick={() => navigate("/playerprofile", { state: item })} >
                                                                <div className="card-header">
                                                                  <div className="card-profile-img">
                                                                    <img src={(item?.image_url) ? item?.image_url : "./img/str4.png"} alt="" />
                                                                  </div>
                                                                  <p className="team-name">
                                                                    {item.player_name}
                                                                  </p>
                                                                </div>
                                                                <div className="card-bottom">
                                                                  <span className="red-rank">F</span>
                                                                  <span className="team">
                                                                    <img
                                                                      src="./img/riverplate.png"
                                                                      alt=""
                                                                    />{" "}
                                                                    MCI
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </Col>
                                                          )
                                                        })
                                                      )
                                                      : (errMsg
                                                        ? (
                                                          <div className="d-flex justify-content-center my-5">
                                                            <div className="pt-2 my-5" >
                                                              {t("¡Error Interno del Servidor!")}
                                                            </div>
                                                          </div>
                                                        )
                                                        : (
                                                          <div className="d-flex justify-content-center my-5 align-items-center">
                                                            <div className="spinner-border my-5 " role="status">
                                                              <span className="sr-only"></span>
                                                            </div>
                                                            <div className="pt-2 my-5" >&nbsp;
                                                              {t("Cargando")}...
                                                            </div>
                                                          </div>
                                                        )
                                                      )
                                                  }
                                                </Row>

                                              </div>

                                              <div className="teampofile-sec defensores-sec">
                                                <h4>{t("Defensores")}</h4>
                                                <Row>
                                                  {
                                                    youthPlayerData
                                                      ? (
                                                        ((youthPlayerData['defender'].length < 1)
                                                          && (
                                                            <div className="d-flex justify-content-center my-5">
                                                              <div className="pt-2 my-5" >
                                                                {t("¡Ningún defensor encontrado!")}
                                                              </div>
                                                            </div>
                                                          )) ||
                                                        youthPlayerData['defender']?.map(item => {
                                                          return (
                                                            <Col xs lg="2">
                                                              <div className="teamprofile-card" onClick={() => navigate("/playerprofile", { state: item })} style={{ cursor: "pointer" }}>
                                                                <div className="card-header">
                                                                  <div className="card-profile-img">
                                                                    <img src={(item?.image_url) ? item?.image_url : "./img/str4.png"} alt="" />
                                                                  </div>
                                                                  <p className="team-name">
                                                                    {item.player_name}
                                                                  </p>
                                                                </div>
                                                                <div className="card-bottom">
                                                                  <span className="red-rank">F</span>
                                                                  <span className="team">
                                                                    <img
                                                                      src="./img/riverplate.png"
                                                                      alt=""
                                                                    />{" "}
                                                                    MCI
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </Col>
                                                          )
                                                        })
                                                      )
                                                      : (errMsg
                                                        ? (
                                                          <div className="d-flex justify-content-center my-5">
                                                            <div className="pt-2 my-5" >
                                                              {t("¡Error Interno del Servidor!")}
                                                            </div>
                                                          </div>
                                                        )
                                                        : (
                                                          <div className="d-flex justify-content-center my-5 align-items-center">
                                                            <div className="spinner-border my-5 " role="status">
                                                              <span className="sr-only"></span>
                                                            </div>
                                                            <div className="pt-2 my-5" >&nbsp;
                                                              {t("Cargando")}...
                                                            </div>
                                                          </div>
                                                        )
                                                      )
                                                  }
                                                </Row>

                                              </div>

                                            </div>
                                          </>
                                        )
                                      }
                                      else if (category === 'Partidos') {
                                        return (<>
                                          {!partidosStatus
                                            ? (<>
                                              <div className='parties-page-sec' key={i}>
                                                <Row>
                                                  <Col xs lg="3">
                                                    <div className='parties-dropdown'>
                                                      <Dropdown>
                                                        <Dropdown.Toggle
                                                          className="menu-dropdown"
                                                          id="dropdown-basic"
                                                        >
                                                          {matchData?.competetion || 'Competencia'}
                                                          {/* {t("Competencia")} */}
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                          {youthLeauge
                                                            && (youthLeauge.map((item) => {
                                                              return (<Dropdown.Item eventKey={item.completeName}>
                                                                {item.completeName}
                                                              </Dropdown.Item>)
                                                            })
                                                            )
                                                          }
                                                        </Dropdown.Menu>
                                                      </Dropdown>
                                                    </div>
                                                  </Col>

                                                  <Col xs lg="6">
                                                    <div className='teampoints-sec'>
                                                      {youthPartido
                                                        ? (Object.keys(youthPartido).map((years, i) => {
                                                          return (
                                                            <div className='teams-points-list-sec'>
                                                              {Object.keys(youthPartido[years]).map((month) => {
                                                                return (
                                                                  <>
                                                                    <h4 className='list-heading'>
                                                                      {monthsList[month].toUpperCase()}-{years}
                                                                    </h4>
                                                                    <ul className='team-points-list'>
                                                                      {youthPartido[years][month]?.map((match) => {
                                                                        return (
                                                                          <li>
                                                                            <div className='matches-history-card position-relative'>

                                                                              <div className="prox-box">
                                                                                <p className="mb-0">
                                                                                  {match?.status}
                                                                                </p>
                                                                              </div>

                                                                              <p className='card-heading'>{menuItem}</p>
                                                                              <div className='card-body'>

                                                                                <div className='left-team'>
                                                                                  <p className='team-name'>{match?.team1_name}</p>
                                                                                  <img src={match?.team1_shield} alt="" />
                                                                                </div>

                                                                                <div className='score-sec'>
                                                                                  <span>{match?.team1_points || 0}</span>
                                                                                  -
                                                                                  <span>{match?.team2_points || 0}</span>
                                                                                </div>

                                                                                <div className='right-team'>
                                                                                  <img src={match?.team2_shield} alt="" />
                                                                                  <p className='team-name'>{match?.team2_name}</p>
                                                                                </div>
                                                                              </div>

                                                                              <div className='card-footer'>
                                                                                <div className='stadium'>
                                                                                  <img src="" alt="" />
                                                                                  <span>{match?.stadium}</span>
                                                                                </div>

                                                                                <div className='date-sec'>
                                                                                  <p className='date'> {getDate(match?.match_date)} </p>
                                                                                </div>

                                                                                <div className='right-sec'>
                                                                                  <img src="" alt="" />
                                                                                  <span>{match?.referee}</span>
                                                                                </div>

                                                                              </div>
                                                                            </div>
                                                                          </li>
                                                                        )
                                                                      })
                                                                      }
                                                                    </ul>
                                                                  </>
                                                                )
                                                              })
                                                              }
                                                            </div>
                                                          )
                                                        })
                                                        )
                                                        : (
                                                          <div className="d-flex justify-content-center my-5 align-items-center">
                                                            <div className="spinner-border my-5 " role="status">
                                                              <span className="sr-only"></span>
                                                            </div>
                                                            <div className="pt-2 my-5" >&nbsp;
                                                              {t("Cargando")}...
                                                            </div>
                                                          </div>
                                                        )
                                                      }

                                                    </div>
                                                  </Col>

                                                  <Col xs lg="3">
                                                  </Col>
                                                </Row>
                                              </div>
                                            </>)
                                            : (<div className="partidos-inner">

                                              <Row>
                                                {/* handle this handleMatchLeauge */}
                                                <Col xs lg="3">
                                                  <div>
                                                    <div className='parties-dropdown'>
                                                      <Dropdown
                                                        onSelect={eventKey => (setMatchData({ ...matchData, 'competetion': eventKey }))}
                                                      >
                                                        <Dropdown.Toggle
                                                          className="menu-dropdown"
                                                          id="dropdown-basic"
                                                        >
                                                          {matchData?.competetion || 'Competencia'}
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                          {youthLeauge
                                                            && (youthLeauge.map((item) => {
                                                              return (<Dropdown.Item eventKey={item.completeName}>
                                                                {item.completeName}
                                                              </Dropdown.Item>)
                                                            })
                                                            )
                                                          }

                                                          {/* 
                                                            <Dropdown.Item eventKey="Torneo de Liga">
                                                              {t("Torneo de Liga")}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item eventKey="Copa nacional">
                                                              {t("Copa nacional")}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item eventKey="Copa Internacional">
                                                              {t("Copa Internacional")}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item eventKey="Amistosos">
                                                              {t("Amistosos")}
                                                            </Dropdown.Item> 
                                                            */}
                                                        </Dropdown.Menu>

                                                      </Dropdown>
                                                    </div>
                                                  </div>
                                                </Col>
                                                <Col xs lg="6">
                                                  <div className='matches-history-card'>

                                                    <div className="prox-box">
                                                      <p className="mb-0">
                                                        {matchData.status === 'finished' ? 'Fin' : 'Prox'}
                                                      </p>
                                                    </div>
                                                    <p className='card-heading'>{matchData?.sub_category}</p>
                                                    <div className='card-body'>

                                                      <div className='left-team'>
                                                        <p className='team-name'>{logo?.team1_name}</p>
                                                        {
                                                          (logo?.team1)
                                                          && (<img src={logo?.team1} alt="" />)
                                                        }
                                                      </div>

                                                      <div className='score-sec'>
                                                        <span>{matchData?.team1_points || 0}</span>
                                                        -
                                                        <span>{matchData?.team2_points || 0}</span>
                                                      </div>
                                                      <div className='right-team'>
                                                        {
                                                          logo?.team2 &&
                                                          <img src={logo?.team2} alt="" />
                                                        }
                                                        <p className='team-name'>{logo?.team2_name}</p>
                                                      </div>
                                                    </div>

                                                    <div className='card-footer'>
                                                      <div className='stadium'>
                                                        <img src="" alt="" />
                                                        <span>{matchData?.stadium}</span>
                                                      </div>
                                                      <div className='date-sec'>
                                                        <p className='date'>
                                                          {getDate() ? getDate() : ''}
                                                        </p>
                                                      </div>
                                                      <div className='right-sec'>
                                                        <img src="" alt="" />
                                                        <span>{matchData?.referee}</span>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="partidos-team-list">
                                                    <div className='feild-sec'>
                                                      <form>
                                                        <div className='feild-card'>
                                                          <p>{t("Equipo Local")}</p>
                                                          <input type="text" list="data1"
                                                            name='team1'
                                                            placeholder={t('¡seleccione el nombre del equipo!')}
                                                            onChange={getTeamId} />
                                                          <datalist id="data1">
                                                            {allTeam.map((member, key) => {
                                                              // console.log("fmdfc: ",allTeam)
                                                              return <option key={key} id={member.team_id} value={member.team_name} />
                                                            }
                                                            )}
                                                          </datalist>

                                                        </div>
                                                        <div className='feild-card'>
                                                          <p>{t("Equipo Local")}</p>
                                                          <input type="text" list="data"
                                                            name='team2'
                                                            placeholder={t('¡seleccione el nombre del equipo!')}
                                                            onChange={getTeamId} />
                                                          <datalist id="data">
                                                            {allTeam.map((member, key) => {
                                                              // console.log("fmdfc: ",allTeam)
                                                              return <option key={key} id={member.team_id} value={member.team_name} />
                                                            }
                                                            )}
                                                          </datalist>
                                                        </div>

                                                        <div className='feild-card'>
                                                          <p>{t("Estado del partido")}</p>
                                                          <select name="status"
                                                            value={matchData.status}
                                                            onChange={handleMatchData}>
                                                            <option value={null} disabled selected hidden >{t("seleccionar estado")}</option>
                                                            <option value='finished'>{t("Final del partido")}</option>
                                                            <option value='pending'>{t("Partido pendiente")}</option>
                                                          </select>
                                                        </div>

                                                        <div className='feild-card'>
                                                          <p>{t("Fecha")}</p>
                                                          <input type="date" name="match_date"
                                                            value={matchData?.match_date}
                                                            onChange={handleMatchData} />
                                                        </div>

                                                        <div className='feild-card'>
                                                          <p>{t("Horario")}</p>
                                                          <input type="time" name="match_time"
                                                            value={matchData?.match_time}
                                                            onChange={handleMatchData} />
                                                        </div>

                                                        <div className='feild-card'>
                                                          <p>{t("Arbitro")}</p>
                                                          <input type="text" name="referee"
                                                            value={matchData?.referee}
                                                            onChange={handleMatchData} />
                                                        </div>

                                                        <div className='feild-card'>
                                                          <p>{t("Estadio")}</p>
                                                          value={matchData?.stadium}
                                                          <input type="text" name="stadium"
                                                            onChange={handleMatchData} />
                                                        </div>

                                                        <div className='feild-card feild-team-score'>
                                                          {/* <img src="./img/riverplate.png" alt="" /> */}
                                                          <img src={logo?.team1} alt="" />
                                                          <h5 className='edit-sec' id='diff_position'>
                                                            <input type="number" name="team1_points"
                                                              value={matchData?.team1_points}
                                                              onChange={handleMatchData} />
                                                          </h5>

                                                          <p>{t("Posición principal")}</p>
                                                          <h5 className='edit-sec' id='diff_position'>
                                                            <input type="number" name="team2_points"
                                                              value={matchData?.team2_points}
                                                              onChange={handleMatchData} />
                                                          </h5>
                                                          <img src={logo?.team2} alt="" />
                                                        </div>

                                                        {/* 
                                                              <div className="center-space-btn">
                                                                  <button className="green-btn" onClick={() => { navigate("/add-otherSelection-news") }}>
                                                                    Agregar Platel
                                                                  </button>
                                                                </div> 
                                                              */}
                                                      </form>

                                                    </div>
                                                  </div>
                                                </Col>
                                                <Col xs lg="3"></Col>
                                              </Row>

                                            </div>
                                            )}
                                        </>
                                        )
                                      }
                                      else if (category === 'Clasificación') {
                                        return (
                                          <Row key={i}>
                                            {/* table */}
                                            <Col xs lg="3" >

                                              <div className="categories-dropdown">
                                                <Dropdown onSelect={handleSelectTournament}>
                                                  <Dropdown.Toggle
                                                    className="dropdown-btn"
                                                    id="dropdown-basic"
                                                    defaultactivekey={juvenilesClassification?.tournament_type}
                                                  // active={menuItem}
                                                  >
                                                    {juvenilesClassification?.tournament_type || "Competencia"}
                                                  </Dropdown.Toggle>

                                                  <Dropdown.Menu>
                                                    <Dropdown.Item eventKey='leauge'>
                                                      {t("Nombre de torneo - Liga")}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item eventKey='playoffs'>
                                                      {t("Nombre de torneo - Playoffs")}
                                                    </Dropdown.Item>

                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>

                                              <div className="categories-dropdown season-drop">
                                                <Dropdown onSelect={handleSeason}>
                                                  <Dropdown.Toggle
                                                    className="dropdown-btn"
                                                    id="dropdown-basic"
                                                    defaultactivekey={juvenilesClassification?.season_id}
                                                  // active={}
                                                  >
                                                    {juvenilesClassification?.season_year || "Año"}
                                                  </Dropdown.Toggle>

                                                  <Dropdown.Menu>
                                                    {seasonsData?.map((season, i) => {
                                                      return (<Dropdown.Item key={i} eventKey={season.season_id}>
                                                        {season.year}
                                                      </Dropdown.Item>)
                                                    })
                                                    }
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>

                                            </Col>

                                            <Col xs lg="6 ">
                                              <div className="custom-drop-wrap">
                                                <div className="categories-dropdown">
                                                  <Dropdown onSelect={handleJornada}>
                                                    <Dropdown.Toggle
                                                      className="dropdown-btn"
                                                      id="dropdown-basic"
                                                      defaultactivekey={juvenilesClassification?.jornada}
                                                    // active={menuItem}
                                                    >
                                                      Jornada {juvenilesClassification?.jornada}
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                      <Dropdown.Item eventKey='1'>
                                                        Jornada 1
                                                      </Dropdown.Item>

                                                      <Dropdown.Item eventKey='2'>
                                                        Jornada 2
                                                      </Dropdown.Item>

                                                      <Dropdown.Item eventKey='3'>
                                                        Jornada 3
                                                      </Dropdown.Item>

                                                      <Dropdown.Item eventKey='4'>
                                                        Jornada 4
                                                      </Dropdown.Item>

                                                      <Dropdown.Item eventKey='5'>
                                                        Jornada 5
                                                      </Dropdown.Item>

                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                </div>
                                              </div>

                                              <div className="admin-menu-sec custom-admin-tabs">
                                                <Tabs
                                                  defaultactivekey="Total"
                                                  id="fill-tab-example"
                                                  onSelect={""} fill
                                                >
                                                  <Tab eventKey="Total" title="Total">

                                                    {(!allYouthClassification)
                                                      ? (
                                                        <>{t("Cargando")}. . . </>
                                                      )
                                                      : (
                                                        <table cellPadding="5" style={{ width: "100%" }}>
                                                          <tr style={{ fontSize: "12px" }}>
                                                            <td></td><td></td>
                                                            <td></td><td></td>
                                                            <td></td><td></td>
                                                            <td></td><td></td>
                                                            <td></td><td></td>

                                                            <td>PTS</td><td>PJ</td>
                                                            <td>PG</td><td>PE</td>
                                                            <td>PP</td><td>GF</td>
                                                            <td>GC</td><td>DG</td>
                                                          </tr>

                                                          {allYouthClassification?.map((classification, i) => {
                                                            return (<tr
                                                              className={`youthQualify-team-color 
                                                              
                                                              ${// eslint-disable-next-line
                                                                (classification.team1_id == juvenilesClassification.team1_id)
                                                                && "youthQualify-team-color-bg"}`}
                                                              key={i}>
                                                              <td className={`youth-classification-td ${checkColor(classification.wc_qualification)
                                                                }`}

                                                              >{i + 1}</td>

                                                              <td> <img className="table-img" src={classification?.team1_logo} alt="" /></td>
                                                              <td className="1-row">
                                                                <p>{classification?.team1_name || 'EQUIPO'}</p>
                                                                {classification?.win_streak?.split("").map((item) => {
                                                                  // console.log('item: ', handleCheckStreak(item));
                                                                  return (<span className={handleCheckStreak(item)}>
                                                                    {item.toUpperCase()}
                                                                  </span>)
                                                                })
                                                                }

                                                                {/* <span>V</span> <span>V</span>
                                                                <span>V</span><span className="extra">D</span>
                                                                <span>V</span> */}
                                                              </td>

                                                              <td></td>
                                                              <td></td><td></td>
                                                              <td></td><td></td>
                                                              <td></td><td></td>

                                                              <td>{classification?.team_points}</td><td>{classification?.matches_played}</td>
                                                              <td>{classification?.won_matches}</td><td>{classification?.draw_matches}</td>
                                                              <td>{classification?.lost_matches}</td><td>{classification?.goal_favour}</td>
                                                              <td>{classification?.goal_against}</td><td>+{classification?.goal_difference}</td>
                                                            </tr>

                                                            )
                                                          })
                                                          }
                                                        </table>
                                                      )
                                                    }
                                                  </Tab>

                                                  <Tab eventKey="Local" title="Local">
                                                  </Tab>

                                                  <Tab eventKey="Visitante" title="Visitante">
                                                  </Tab>

                                                </Tabs>
                                              </div>
                                            </Col>

                                            <Col xs lg="3 ">
                                              <div className="categories-dropdown drop-team-list"
                                                style={{ textAlign: "center" }}>
                                                <Dropdown onSelect={handleSelectTeam}>
                                                  <Dropdown.Toggle
                                                    className="dropdown-btn"
                                                    id="dropdown-basic"
                                                    defaultactivekey={juvenilesClassification?.team1_id}
                                                    active={menuItem}>
                                                    {juvenilesClassification?.team1_name || "Equipo"}
                                                  </Dropdown.Toggle>

                                                  <Dropdown.Menu>
                                                    {allTeam?.map((team, i) => {
                                                      return (<Dropdown.Item eventKey={team.team_id} key={i}>
                                                        <img className="team-flag-icon" src={team.team_logo_url} alt=""></img>
                                                        {team.team_name}
                                                      </Dropdown.Item>)
                                                    })
                                                    }
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>

                                              <div className="box-image">
                                                <img src={juvenilesClassification?.team1_logo} alt="" />

                                                <button className="blue-btn orange-btn"
                                                  onClick={handleShowEquipo}>
                                                  {t("EDITAR EQUIPO")}
                                                </button>
                                              </div>

                                            </Col>
                                          </Row>)
                                      }
                                      return null;
                                    })}

                                    <div className="text-danger text-center">
                                      {errMsg && '¡Error Interno del Servidor!'}
                                    </div>

                                    <div className="center-space-btn">
                                      <button className="green-btn m-0" onClick={handleBtnClick}>
                                        {btntxt}
                                      </button>
                                      {(category === 'Partidos' && partidosStatus)
                                        && (<button className="red-btn m-0" onClick={handleBackFrPartidos}>
                                          {t("Atrás")}
                                        </button>)}
                                    </div>
                                  </>
                                  )
                                  : (
                                    <div className="d-flex justify-content-center my-5">
                                      <div className="pt-2 my-5" >
                                        {t("¡Datos no encontrados!")}
                                      </div>
                                    </div>
                                  )
                                )
                                : (errMsg
                                  ? (
                                    <div className="d-flex justify-content-center my-5">
                                      <div className="pt-2 my-5" >
                                        {t("¡Error Interno del Servidor!")}
                                      </div>
                                    </div>
                                  )
                                  : (
                                    <div className="d-flex justify-content-center my-5 align-items-center">
                                      <div className="spinner-border my-5 " role="status">
                                        <span className="sr-only"></span>
                                      </div>
                                      <div className="pt-2 my-5" >&nbsp;
                                        {t("Cargando")}...
                                      </div>
                                    </div>
                                  )
                                )}

                              {!(youthData?.length)
                                && (<div className="center-space-btn">
                                  <button className="green-btn m-0" onClick={handleBtnClick}>
                                    {btntxt}
                                  </button>
                                </div>)
                              }
                            </Row>
                          </div>
                        </div>

                      </Tab>

                      <Tab eventKey="otras-selecciones" title={t("Otras selecciones")} >

                        <div className="radio-btns-sec">
                          <div className="left-radios-sec">
                            <div className="radio-sec">
                              <input
                                type="radio"
                                id="test5"
                                name="radio-group"
                              />
                              <label htmlFor="test5">{t("Cuadrícula")}</label>
                            </div>
                            <div className="radio-sec">
                              <input
                                type="radio"
                                id="test6"
                                name="radio-group"
                              />
                              <label htmlFor="test6">{t("Lista")}</label>
                            </div>
                          </div>

                          <div className="categories-dropdown">
                            <Dropdown onSelect={handleMenuItem}>
                              <Dropdown.Toggle
                                className="dropdown-btn"
                                id="dropdown-basic"
                                defaultactivekey='Senior'
                                active={menuItem}
                              >
                                {displayOtrasSelecion || 'Selección'}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item eventKey="senior">
                                  {t("Senior")}
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="bats">
                                  {t("Los Murciélagos")}
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="mensfutsal">
                                  {t("Mens Futsal")}
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="womensfutsal">
                                  {t("Womens Futsal")}
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="beachsoccer">
                                  {t("Futbol Playa")}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>

                          <div className="right-radios-sec">
                            <div className="check-box">
                              <span>{t("Masculino")}</span>
                              <input type="checkbox" />
                              <span>{t("Femenino")}</span>
                            </div>
                          </div>
                        </div>

                        <div className="juveniles-sec">
                          <div className="juveniles-sec-container scrollbar-plantel">
                            <Row>
                              {otherSelection
                                ? (otherSelection?.length
                                  ? (<>
                                    {otherSelection?.map((item, i) => {
                                      if (category === 'Info / Noticias') {
                                        return (
                                          <>
                                            <Col xs lg="4" key={item.news_id}
                                              onClick={() =>
                                                navigate(`/teamadministration/otherSelection/edit-otherSelection-news/${item.news_id}`,
                                                  { state: item })
                                              }>
                                              <div className="teampofile-outer h-auto">
                                                <div className="juveniles-card news-card">
                                                  <div className="card-heading">
                                                    <p>{item?.title}</p>
                                                  </div>
                                                  <div className="card-img-sec">
                                                    <img src={(item?.news_media)
                                                      ? (item?.news_media[0]?.url)
                                                      : ("./img/blankimg.png")
                                                    }
                                                      alt="" />
                                                  </div>
                                                </div>
                                              </div>
                                            </Col>
                                          </>
                                        )
                                      }
                                      else if (category === 'Plantel') {
                                        return (
                                          <>
                                            <div className="teampofile-outer " key={i}>
                                              <div className="teampofile-sec forwords-sec forwords-col">
                                                <h4>{t("Portero")}</h4>
                                                <Row>
                                                  {otherPlayerData
                                                    ? (
                                                      ((otherPlayerData['goalkeeper'].length < 1)
                                                        && (
                                                          <div className="d-flex justify-content-center my-5">
                                                            <div className="pt-2 my-5" >
                                                              {t("¡No se encontró ningún portero!")}
                                                            </div>
                                                          </div>
                                                        )) ||
                                                      otherPlayerData['goalkeeper']?.map((item, i) => {
                                                        return (
                                                          <Col xs lg="2" key={i}
                                                            onClick={() => navigate("/playerprofile", { state: item })}
                                                            style={{ cursor: "pointer" }} >
                                                            <div className="teamprofile-card" >
                                                              <div className="card-header">
                                                                <div className="card-profile-img">
                                                                  <img src={(item?.image_url)
                                                                    ? item?.image_url
                                                                    : "./img/str4.png"} alt="" />
                                                                </div>
                                                                <p className="team-name">
                                                                  {item.player_name}
                                                                </p>
                                                              </div>
                                                              <div className="card-bottom">
                                                                <span className="red-rank">F</span>
                                                                <span className="team">
                                                                  <img src="./img/riverplate.png"
                                                                    alt=""
                                                                  />{" "}
                                                                  MCI
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </Col>
                                                        )
                                                      })
                                                    )
                                                    : (errMsg
                                                      ? (
                                                        <div className="d-flex justify-content-center my-5">
                                                          <div className="pt-2 my-5" >
                                                            {t("¡Error Interno del Servidor!")}
                                                          </div>
                                                        </div>
                                                      )
                                                      : (
                                                        <div className="d-flex justify-content-center my-5 align-items-center">
                                                          <div className="spinner-border my-5 " role="status">
                                                            <span className="sr-only"></span>
                                                          </div>
                                                          <div className="pt-2 my-5" >&nbsp;
                                                            {t("Cargando")}...
                                                          </div>
                                                        </div>
                                                      )
                                                    )
                                                  }
                                                </Row>
                                              </div>
                                              <div className="teampofile-sec forwords-sec">
                                                <h4>{t("Delanteros")}</h4>
                                                <Row>
                                                  {otherPlayerData
                                                    ? (
                                                      ((otherPlayerData['attacker'].length < 1)
                                                        && (
                                                          <div className="d-flex justify-content-center my-5">
                                                            <div className="pt-2 my-5" >
                                                              {t("¡No se encontró ningún delantero!")}
                                                            </div>
                                                          </div>
                                                        )) ||
                                                      otherPlayerData['attacker']?.map((item, i) => {
                                                        return (
                                                          <Col xs lg="2" key={i}
                                                            onClick={() => navigate("/playerprofile", { state: item })}
                                                            style={{ cursor: "pointer" }} >
                                                            <div className="teamprofile-card" >
                                                              <div className="card-header">
                                                                <div className="card-profile-img">
                                                                  <img src={(item?.image_url) ? item?.image_url : "./img/str4.png"} alt="" />
                                                                </div>
                                                                <p className="team-name">
                                                                  {item.player_name}
                                                                </p>
                                                              </div>
                                                              <div className="card-bottom">
                                                                <span className="red-rank">F</span>
                                                                <span className="team">
                                                                  <img
                                                                    src="./img/riverplate.png"
                                                                    alt=""
                                                                  />{" "}
                                                                  MCI
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </Col>
                                                        )
                                                      })
                                                    )
                                                    : (errMsg
                                                      ? (
                                                        <div className="d-flex justify-content-center my-5">
                                                          <div className="pt-2 my-5" >
                                                            {t("¡Error Interno del Servidor!")}
                                                          </div>
                                                        </div>
                                                      )
                                                      : (
                                                        <div className="d-flex justify-content-center my-5 align-items-center">
                                                          <div className="spinner-border my-5 " role="status">
                                                            <span className="sr-only"></span>
                                                          </div>
                                                          <div className="pt-2 my-5" >&nbsp;
                                                            {t("Cargando")}...
                                                          </div>
                                                        </div>
                                                      )
                                                    )
                                                  }
                                                </Row>
                                              </div>
                                              <div className="teampofile-sec midfielders-sec">
                                                <h4>{t("Mediocampista")}</h4>
                                                <Row>
                                                  {otherPlayerData
                                                    ? (
                                                      ((otherPlayerData['midfielder'].length < 1)
                                                        && (
                                                          <div className="d-flex justify-content-center my-5">
                                                            <div className="pt-2 my-5" >
                                                              {t("¡No se encontraron mediocampistas!")}
                                                            </div>
                                                          </div>
                                                        )) ||
                                                      otherPlayerData['midfielder']?.map((item, i) => {
                                                        return (
                                                          <Col xs lg="2" key={i}>
                                                            <div className="teamprofile-card" onClick={() => navigate("/playerprofile", { state: item })} style={{ cursor: "pointer" }}>
                                                              <div className="card-header">
                                                                <div className="card-profile-img">
                                                                  <img src={(item?.image_url) ? item?.image_url : "./img/str4.png"} alt="" />
                                                                </div>
                                                                <p className="team-name">
                                                                  {item.player_name}
                                                                </p>
                                                              </div>
                                                              <div className="card-bottom">
                                                                <span className="red-rank">F</span>
                                                                <span className="team">
                                                                  <img
                                                                    src="./img/riverplate.png"
                                                                    alt=""
                                                                  />{" "}
                                                                  MCI
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </Col>
                                                        )
                                                      })
                                                    )
                                                    : (errMsg
                                                      ? (
                                                        <div className="d-flex justify-content-center my-5">
                                                          <div className="pt-2 my-5" >
                                                            {t("¡Error Interno del Servidor!")}
                                                          </div>
                                                        </div>
                                                      )
                                                      : (
                                                        <div className="d-flex justify-content-center my-5 align-items-center">
                                                          <div className="spinner-border my-5 " role="status">
                                                            <span className="sr-only"></span>
                                                          </div>
                                                          <div className="pt-2 my-5" >&nbsp;
                                                            {t("Cargando")}...
                                                          </div>
                                                        </div>
                                                      )
                                                    )
                                                  }
                                                </Row>

                                              </div>
                                              <div className="teampofile-sec defensores-sec">
                                                <h4>{t("Defensores")}</h4>
                                                <Row>
                                                  {otherPlayerData
                                                    ? (
                                                      ((otherPlayerData['defender'].length < 1)
                                                        && (
                                                          <div className="d-flex justify-content-center my-5">
                                                            <div className="pt-2 my-5" >
                                                              {t("¡Ningún defensor encontrado!")}
                                                            </div>
                                                          </div>
                                                        )) ||
                                                      otherPlayerData['defender']?.map((item, i) => {
                                                        return (
                                                          <Col xs lg="2" key={i}>
                                                            <div className="teamprofile-card" onClick={() => navigate("/playerprofile", { state: item })} style={{ cursor: "pointer" }}>
                                                              <div className="card-header">
                                                                <div className="card-profile-img">
                                                                  <img src={(item?.image_url) ? item?.image_url : "./img/str4.png"} alt="" />
                                                                </div>
                                                                <p className="team-name">
                                                                  {item.player_name}
                                                                </p>
                                                              </div>
                                                              <div className="card-bottom">
                                                                <span className="red-rank">F</span>
                                                                <span className="team">
                                                                  <img
                                                                    src="./img/riverplate.png"
                                                                    alt=""
                                                                  />{" "}
                                                                  MCI
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </Col>
                                                        )
                                                      })
                                                    )
                                                    : (errMsg
                                                      ? (
                                                        <div className="d-flex justify-content-center my-5">
                                                          <div className="pt-2 my-5" >
                                                            {t("¡Error Interno del Servidor!")}
                                                          </div>
                                                        </div>
                                                      )
                                                      : (
                                                        <div className="d-flex justify-content-center my-5 align-items-center">
                                                          <div className="spinner-border my-5 " role="status">
                                                            <span className="sr-only"></span>
                                                          </div>
                                                          <div className="pt-2 my-5" >&nbsp;
                                                            {t("Cargando")}...
                                                          </div>
                                                        </div>
                                                      )
                                                    )
                                                  }
                                                </Row>

                                              </div>
                                            </div>

                                          </>
                                        )
                                      }
                                      else if (category === 'Partidos') {
                                        return (
                                          <>
                                            {!partidosStatus
                                              ? (<>
                                                <div className='parties-page-sec' key={i}>
                                                  <Row>
                                                    <Col xs lg="3">
                                                      <div className='parties-dropdown'>
                                                        <Dropdown
                                                          onSelect={eventKey => (setOtherSelectionCompetencia(eventKey))}>
                                                          <Dropdown.Toggle

                                                            className="menu-dropdown"
                                                            id="dropdown-basic"
                                                          >
                                                            {otherSelectionCompetencia || t("Competencia")}
                                                          </Dropdown.Toggle>

                                                          <Dropdown.Menu>
                                                            {youthLeauge
                                                              && (youthLeauge.map((item) => {
                                                                return (<Dropdown.Item eventKey={item.completeName}>
                                                                  {item.completeName}
                                                                </Dropdown.Item>)
                                                              })
                                                              )
                                                            }

                                                            {/* 
                                                                  <Dropdown.Item href="#/action-1">
                                                                    {t("Torneo de Liga")}
                                                                  </Dropdown.Item>
                                                                  <Dropdown.Item href="#/action-2">
                                                                    {t("Copa nacional")}
                                                                  </Dropdown.Item>
                                                                  <Dropdown.Item href="#/action-3">
                                                                    {t("Copa Internacional")}
                                                                  </Dropdown.Item>
                                                                  <Dropdown.Item href="#/action-3">
                                                                    {t("Amistosos")}
                                                                  </Dropdown.Item> 
                                                                  <Dropdown.Item href="#/action-3">
                                                                      + agregar competencia
                                                                    </Dropdown.Item>
                                                                  */}

                                                          </Dropdown.Menu>
                                                        </Dropdown>
                                                      </div>
                                                    </Col>

                                                    <Col xs lg="6">
                                                      <div className='teampoints-sec'>
                                                        {otherPartido
                                                          ? (Object.keys(otherPartido).map((years, i) => {
                                                            return (
                                                              <div className='teams-points-list-sec'>
                                                                {Object.keys(otherPartido[years]).map((month) => {
                                                                  return (
                                                                    <>
                                                                      <h4 className='list-heading'>
                                                                        {monthsList[month].toUpperCase()}-{years}
                                                                      </h4>

                                                                      <ul className='team-points-list'>
                                                                        {otherPartido[years][month]?.map((match) => {
                                                                          return (
                                                                            <li>
                                                                              <div className='matches-history-card position-relative'>
                                                                                <div className="prox-box">
                                                                                  <p className="mb-0">
                                                                                    {match?.status}
                                                                                  </p>
                                                                                </div>

                                                                                <p className='card-heading'>{menuItem}</p>
                                                                                <div className='card-body'>

                                                                                  <div className='left-team'>
                                                                                    <p className='team-name'>{match?.team1_name}</p>
                                                                                    {
                                                                                      match?.team1_shield
                                                                                      && <img src={match?.team1_shield} alt="" />
                                                                                    }
                                                                                  </div>

                                                                                  <div className='score-sec'>
                                                                                    <span>{match?.team1_points || 0}</span>
                                                                                    -
                                                                                    <span>{match?.team2_points || 0}</span>
                                                                                  </div>

                                                                                  <div className='right-team'>
                                                                                    {
                                                                                      match?.team2_shield
                                                                                      && (<img src={match?.team2_shield} alt="" />)
                                                                                    }
                                                                                    <p className='team-name'>{match?.team2_name}</p>
                                                                                  </div>

                                                                                </div>
                                                                                <div className='card-footer'>
                                                                                  <div className='stadium'>
                                                                                    <img src="" alt="" />
                                                                                    <span>{match?.stadium}</span>
                                                                                  </div>
                                                                                  <div className='date-sec'>
                                                                                    <p className='date'> {getDate(match?.match_date)} </p>
                                                                                  </div>
                                                                                  <div className='right-sec'>
                                                                                    <img src="" alt="" />
                                                                                    <span>{match?.referee}</span>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </li>
                                                                          )
                                                                        })
                                                                        }
                                                                      </ul>
                                                                    </>
                                                                  )
                                                                })
                                                                }
                                                              </div>
                                                            )
                                                          })
                                                          )
                                                          : (
                                                            <div className="d-flex justify-content-center my-5 align-items-center">
                                                              <div className="spinner-border my-5 " role="status">
                                                                <span className="sr-only"></span>
                                                              </div>
                                                              <div className="pt-2 my-5" >&nbsp;
                                                                {t("Cargando")}...
                                                              </div>
                                                            </div>
                                                          )
                                                        }

                                                      </div>
                                                    </Col>

                                                    <Col xs lg="3">
                                                    </Col>
                                                  </Row>
                                                </div>

                                                {/* 
                                                    <div className="text-danger text-center">
                                                        {errMsg && '¡Error Interno del Servidor!'}
                                                      </div>
                                                      <div className="center-space-btn">
                                                        <button className="green-btn" onClick={() => setPartidosStatus(true)}>
                                                          {btntxt}
                                                        </button>
                                                      </div> 
                                                    */}

                                              </>)
                                              : (
                                                <div className="partidos-inner">
                                                  <Row>
                                                    {/* handle this handleMatchLeauge */}
                                                    <Col xs lg="3">
                                                      <div>
                                                        <div className='parties-dropdown'>
                                                          <Dropdown
                                                            onSelect={eventKey => (setMatchData({ ...matchData, 'competetion': eventKey }))}
                                                          >
                                                            <Dropdown.Toggle
                                                              className="menu-dropdown"
                                                              id="dropdown-basic"
                                                            >
                                                              {matchData?.competetion || 'Competencia'}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                              <Dropdown.Item eventKey="Torneo de Liga">
                                                                {t("Torneo de Liga")}
                                                              </Dropdown.Item>
                                                              <Dropdown.Item eventKey="Copa nacional">
                                                                {t("Copa nacional")}
                                                              </Dropdown.Item>
                                                              <Dropdown.Item eventKey="Copa Internacional">
                                                                {t("Copa Internacional")}
                                                              </Dropdown.Item>
                                                              <Dropdown.Item eventKey="Amistosos">
                                                                {t("Amistosos")}
                                                              </Dropdown.Item>
                                                            </Dropdown.Menu>

                                                          </Dropdown>
                                                        </div>
                                                      </div>
                                                    </Col>
                                                    <Col xs lg="6">
                                                      <div className='matches-history-card'>

                                                        <div className="prox-box">
                                                          <p className="mb-0">
                                                            {matchData.status === 'finished'
                                                              ? 'Fin'
                                                              : 'Prox'
                                                            }
                                                          </p>
                                                        </div>

                                                        <p className='card-heading'>{matchData?.sub_category}</p>
                                                        <div className='card-body'>
                                                          <div className='left-team'>
                                                            <p className='team-name'>{logo?.team1_name}</p>
                                                            {logo?.team1 && <img src={logo?.team1} alt="" />}
                                                          </div>

                                                          <div className='score-sec'>
                                                            <span>{matchData?.team1_points || 0}</span>
                                                            -
                                                            <span>{matchData?.team2_points || 0}</span>
                                                          </div>

                                                          <div className='right-team'>
                                                            {logo?.team2 && <img src={logo?.team2} alt="" />}
                                                            <p className='team-name'>{logo?.team2_name}</p>
                                                          </div>
                                                        </div>

                                                        <div className='card-footer'>
                                                          <div className='stadium'>
                                                            <img src="" alt="" />
                                                            <span>{matchData?.stadium}</span>
                                                          </div>
                                                          <div className='date-sec'>
                                                            <p className='date'>
                                                              {getDate()}
                                                            </p>
                                                          </div>
                                                          <div className='right-sec'>
                                                            <img src="" alt="" />
                                                            <span>{matchData?.referee}</span>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="partidos-team-list">
                                                        <div className='feild-sec'>
                                                          <form>
                                                            <div className='feild-card'>
                                                              <p>{t("otro equipo")}</p>

                                                              <input type="text" list="data1"
                                                                name='team1'
                                                                placeholder={t('¡seleccione el nombre del equipo!')}
                                                                onChange={getTeamId} />

                                                              <datalist id="data1">
                                                                {allTeam.map((member, key) => {
                                                                  // console.log("fmdfc: ",allTeam)
                                                                  return <option key={key} id={member.team_id} value={member.team_name} />
                                                                }
                                                                )}
                                                              </datalist>

                                                            </div>

                                                            <div className='feild-card'>
                                                              <p>{t("Equipo Local")}</p>
                                                              <input type="text" list="data"
                                                                name='team2'
                                                                placeholder={t('¡seleccione el nombre del equipo!')}
                                                                onChange={getTeamId} />
                                                              <datalist id="data">
                                                                {allTeam.map((member, key) => {
                                                                  // console.log("fmdfc: ",allTeam)
                                                                  return <option key={key} id={member.team_id} value={member.team_name} />
                                                                }
                                                                )}
                                                              </datalist>
                                                            </div>

                                                            <div className='feild-card'>
                                                              <p>{t("Estado del partido")}</p>
                                                              <select name="status"
                                                                value={matchData.status}
                                                                onChange={handleMatchData}>
                                                                <option value={null} hiddden selected disabled>{t("seleccionar estado")}</option>
                                                                <option value='finished'>{t("Final del partido")}</option>
                                                                <option value='pending'>{t("Partido pendiente")}</option>
                                                              </select>
                                                            </div>

                                                            <div className='feild-card'>
                                                              <p>{t("Fecha")}</p>
                                                              <input type="date" name="match_date"
                                                                value={matchData?.match_date}
                                                                onChange={handleMatchData} />
                                                            </div>

                                                            <div className='feild-card'>
                                                              <p>{t("Horario")}</p>
                                                              <input type="time" name="match_time"
                                                                value={matchData?.match_time}
                                                                onChange={handleMatchData} />
                                                            </div>
                                                            <div className='feild-card'>
                                                              <p>{t("Arbitro")}</p>
                                                              <input type="text" name="referee"
                                                                value={matchData?.referee}
                                                                onChange={handleMatchData} />
                                                            </div>
                                                            <div className='feild-card'>
                                                              <p>{t("Estadio")}</p>
                                                              <input type="text" name="stadium"
                                                                value={matchData?.stadium}
                                                                onChange={handleMatchData} />
                                                            </div>

                                                            <div className='feild-card feild-team-score'>
                                                              {/* <img src="./img/riverplate.png" alt="" /> */}
                                                              <img src={logo?.team1} alt="" />
                                                              <h5 className='edit-sec' id='diff_position'>
                                                                <input type="number" name="team1_points"
                                                                  value={matchData?.team1_points}
                                                                  onChange={handleMatchData} />
                                                              </h5>
                                                              <p>{t("Posición principal")}</p>
                                                              <h5 className='edit-sec' id='diff_position'>
                                                                <input type="number" name="team2_points"
                                                                  value={matchData?.team2_points}
                                                                  onChange={handleMatchData} />
                                                              </h5>
                                                              <img src={logo?.team2} alt="" />
                                                            </div>

                                                            {/* 
                                                              <div className="center-space-btn">
                                                                  <button className="green-btn" onClick={() => { navigate("/add-otherSelection-news") }}>
                                                                    Agregar Platel
                                                                  </button>
                                                                </div>
                                                              */}
                                                          </form>

                                                        </div>
                                                      </div>
                                                    </Col>
                                                    <Col xs lg="3"></Col>
                                                  </Row>

                                                </div>
                                              )}
                                          </>
                                        )
                                      }
                                      else if (category === 'Clasificación') {
                                        return (
                                          <Row key={i}>
                                            {/* table */}
                                            <Col xs lg="3" >

                                              <div className="categories-dropdown">
                                                <Dropdown onSelect={handleSelectTournament}>
                                                  <Dropdown.Toggle
                                                    className="dropdown-btn"
                                                    id="dropdown-basic"
                                                    defaultactivekey={otherClassification?.tournament_type}
                                                  // active={menuItem}
                                                  >
                                                    {otherClassification?.tournament_type || "Competencia"}
                                                  </Dropdown.Toggle>

                                                  <Dropdown.Menu>
                                                    <Dropdown.Item eventKey='leauge'>
                                                      {t("Nombre de torneo - Liga")}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item eventKey='playoffs'>
                                                      {t("Nombre de torneo - Playoffs")}
                                                    </Dropdown.Item>

                                                  </Dropdown.Menu>

                                                </Dropdown>
                                              </div>

                                              <div className="categories-dropdown season-drop">
                                                <Dropdown onSelect={handleSeason}>
                                                  <Dropdown.Toggle
                                                    className="dropdown-btn"
                                                    id="dropdown-basic"
                                                    defaultactivekey={otherClassification?.season_id}
                                                  // active={}
                                                  >
                                                    {otherClassification?.season_year || "Año"}
                                                  </Dropdown.Toggle>

                                                  <Dropdown.Menu>
                                                    {seasonsData?.map((season, i) => {
                                                      return (<Dropdown.Item key={i} eventKey={season.season_id}>
                                                        {season.year}
                                                      </Dropdown.Item>)
                                                    })
                                                    }
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>

                                            </Col>

                                            <Col xs lg="6 ">
                                              <div className="custom-drop-wrap">
                                                <div className="categories-dropdown">
                                                  <Dropdown onSelect={handleJornada}>
                                                    <Dropdown.Toggle
                                                      className="dropdown-btn"
                                                      id="dropdown-basic"
                                                      defaultactivekey={otherClassification?.jornada}
                                                    // active={menuItem}
                                                    >
                                                      Jornada {otherClassification?.jornada}
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                      <Dropdown.Item eventKey='1'>
                                                        Jornada 1
                                                      </Dropdown.Item>

                                                      <Dropdown.Item eventKey='2'>
                                                        Jornada 2
                                                      </Dropdown.Item>

                                                      <Dropdown.Item eventKey='3'>
                                                        Jornada 3
                                                      </Dropdown.Item>

                                                      <Dropdown.Item eventKey='4'>
                                                        Jornada 4
                                                      </Dropdown.Item>

                                                      <Dropdown.Item eventKey='5'>
                                                        Jornada 5
                                                      </Dropdown.Item>

                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                </div>
                                              </div>

                                              <div className="admin-menu-sec custom-admin-tabs">
                                                <Tabs
                                                  defaultactivekey="Total"
                                                  id="fill-tab-example"
                                                  onSelect={""} fill
                                                >
                                                  <Tab eventKey="Total" title="Total">
                                                    <table cellPadding="5" style={{ width: "100%" }}>
                                                      <tr style={{ fontSize: "8px" }}>
                                                        <td></td><td></td>
                                                        <td></td><td></td>
                                                        <td></td><td></td>
                                                        <td></td><td></td>
                                                        <td></td><td></td>

                                                        <td>PTS</td><td>PJ</td>
                                                        <td>PG</td><td>PE</td>
                                                        <td>PP</td><td>GF</td>
                                                        <td>GC</td><td>DG</td>
                                                      </tr>

                                                      {(loadYouthClass && !allOtherClassification)
                                                        && (
                                                          <>{t("Cargando")}. . . </>
                                                        )
                                                      }

                                                      {allOtherClassification?.map((classification, i) => {
                                                        return (<tr className={`youthQualify-team-color 
                                                          
                                                          ${// eslint-disable-next-line
                                                          (classification.team1_id == otherClassification.team1_id)
                                                          && "youthQualify-team-color-bg"}`}>
                                                          <td className={`youth-classification-td ${checkColor(classification.wc_qualification)
                                                            }`}>{i + 1}</td>

                                                          <td> <img className="table-img" src={classification?.team1_logo} alt="" /></td>
                                                          <td className="1-row">
                                                            <p>{classification?.team1_name || 'EQUIPO'}</p>

                                                            {classification?.win_streak?.split("").map((item) => {
                                                              // console.log('item: ', handleCheckStreak(item));
                                                              return (<span className={handleCheckStreak(item)}>
                                                                {item.toUpperCase()}
                                                              </span>)
                                                            })
                                                            }

                                                            {/* <span>V</span> <span>V</span>
                                                            <span className="extra">D</span>
                                                            <span>V</span><span>V</span> */}
                                                          </td>

                                                          <td></td><td></td>
                                                          <td></td><td></td>
                                                          <td></td><td></td>
                                                          <td></td>

                                                          <td>{classification?.team_points}</td><td>{classification?.matches_played}</td>
                                                          <td>{classification?.won_matches}</td><td>{classification?.draw_matches}</td>
                                                          <td>{classification?.lost_matches}</td><td>{classification?.goal_favour}</td>
                                                          <td>{classification?.goal_against}</td><td>+{classification?.goal_difference}</td>
                                                        </tr>

                                                        )
                                                      })
                                                      }
                                                    </table>
                                                  </Tab>

                                                  <Tab eventKey="Local" title="Local">
                                                  </Tab>

                                                  <Tab eventKey="Visitante" title="Visitante">
                                                  </Tab>
                                                </Tabs>
                                              </div>
                                            </Col>

                                            <Col xs lg="3 ">
                                              <div className="categories-dropdown"
                                                style={{ textAlign: "center" }}>
                                                <Dropdown onSelect={handleSelectTeam}>
                                                  <Dropdown.Toggle
                                                    className="dropdown-btn"
                                                    id="dropdown-basic"
                                                    defaultactivekey={otherClassification?.team1_id}
                                                    active={menuItem}>
                                                    {/* <input type="text" name="search-team" value={''}> </input> */}
                                                    {otherClassification?.team1_name || "Equipo"}
                                                  </Dropdown.Toggle>

                                                  <Dropdown.Menu>
                                                    {allTeam?.map((team, i) => {
                                                      return (<Dropdown.Item eventKey={team.team_id} key={i}>
                                                        <img className="team-flag-icon" src={team.team_logo_url} alt=""></img>
                                                        {team.team_name}
                                                      </Dropdown.Item>)
                                                    })
                                                    }
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>

                                              <div className="box-image">
                                                <img src={otherClassification?.team1_logo} alt="" />

                                                <button className="blue-btn orange-btn"
                                                  onClick={handleShowOther}>
                                                  {t("EDITAR EQUIPO")}
                                                </button>
                                              </div>

                                            </Col>
                                          </Row>)
                                      }
                                      return null;
                                    })}

                                    <div className="text-danger text-center">
                                      {errMsg && t('¡Error Interno del Servidor!')}
                                    </div>
                                    <div className="center-space-btn">
                                      <button className="green-btn m-0" onClick={handleBtnClick}>
                                        {btntxt}
                                      </button>
                                      {(category === 'Partidos' && partidosStatus)
                                        && (<button className="red-btn m-0" onClick={handleBackFrPartidos}>
                                          Back
                                        </button>)}
                                    </div>
                                  </>
                                  )
                                  : (
                                    <div className="d-flex justify-content-center my-5">
                                      <div className="pt-2 my-5" >
                                        {t("¡Datos no encontrados!")}
                                      </div>
                                    </div>
                                  )
                                )
                                : (errMsg
                                  ? (
                                    <div className="d-flex justify-content-center my-5">
                                      <div className="pt-2 my-5" >
                                        {t("¡Error Interno del Servidor!")}
                                      </div>
                                    </div>
                                  )
                                  : (
                                    <div className="d-flex justify-content-center align-items-center my-5">
                                      <div className="spinner-border my-5 " role="status">
                                        <span className="sr-only"></span>
                                      </div>
                                      <div className="pt-2 my-5" >&nbsp;
                                        {t("Cargando")}...
                                      </div>
                                    </div>
                                  )
                                )
                              }

                              {!(otherSelection?.length)
                                && (<div className="center-space-btn">
                                  <button className="green-btn m-0" onClick={handleBtnClick}>
                                    {btntxt}
                                  </button>
                                </div>)
                              }
                            </Row>
                          </div>
                        </div>
                      </Tab>

                    </Tabs>
                  </div>
                </Col>

                <Col xs lg="3">
                </Col>
              </Row>

              <Modal show={addStaffForm} className="boardMeeting-popup common-popup"
                onHide={handleClose} centered>
                <Modal.Body >
                  <div className='addboardingsec'>
                    <form>
                      <div className='blur-form-sec'>

                        <div className='blur-form-control'>
                          <label htmlFor="">{t("NOMBRE DEL PERSONAL")}</label>
                          <input className='blur-input'
                            type="text" name='staff_name'
                            value={staffDetails?.staff_name}
                            onChange={handleAddStaffData} />
                        </div>

                        <div className='blur-form-control'>
                          <label htmlFor="">{t("DEPARTAMENTO SI PERSONAL")}</label>
                          <select className="gender-selection staff-category"
                            name="staff_category" value={staffDetails?.staff_category}
                            defaultValue={""}
                            onChange={handleAddStaffData}>

                            <option value={null} disabled selected hidden>{t("seleccionar posición")}</option>
                            <option value="CUERPO TÉCNICO">{t("CUERPO TÉCNICO")}</option>
                            <option value="GESTIÓN">{("GESTIÓN")}</option>
                            <option value="DEPTO MÉDICO">{t("DEPTO MÉDICO")}</option>
                            <option value="DEPTO DE SCOUTING">{t("DEPTO DE SCOUTING")}</option>
                          </select>
                        </div>

                        <div className='blur-form-control'>
                          <label htmlFor="">{t("POSICIÓN DEL PERSONAL")}</label>

                          <select className="gender-selection staff-category"
                            defailtValue={""}
                            name="staff_sub_category" value={staffDetails?.staff_sub_category}
                            onChange={handleAddStaffData}>

                            <option value={null} disabled selected hidden>{t("seleccionar departamento")}</option>
                            <option value="Entrenador">{t("Entrenador")}</option>
                            <option value="Segundo entrenador">{t("Segundo entrenador")}</option>
                            <option value="médico">{t("médico")}</option>
                            <option value="Coordinador de arqueros">{t("Coordinador de arqueros")}</option>
                            <option value="Entrenador de porteros">{t("Entrenador de porteros")}</option>
                            <option value="Preparador físico">{t("Preparador físico")}</option>
                            <option value="Analista de vídeo">{t("Analista de vídeo")}</option>
                            <option value="Gerente General">{t("Gerente General")}</option>
                            <option value="Secretaria técnica">{t("Secretaria técnica")}</option>
                            <option value="Coordinador de cantera">{t("Coordinador de cantera")}</option>
                            <option value="Coordinador de base">{t("Coordinador de base")}</option>

                          </select>
                        </div>

                        <div className="d-flex justify-content-center">

                          <div className='blur-form-control w-100'>
                            <label htmlFor="">{t("GÉNERO DEL PERSONAL")}</label>
                            <select className="gender-selection"
                              name="gender" value={staffDetails?.gender}
                              defaultValue={""}
                              onChange={handleAddStaffData}>

                              <option value={null} disabled selected hidden>{t("Seleccione género")}</option>
                              <option value="male">{t("Male")}</option>
                              <option value="female">{t("Female")}</option>
                            </select>
                          </div>

                          <div className='blur-form-control w-100'>
                            <label htmlFor="">{t("EDAD DEL PERSONAL")}</label>
                            <input className='blur-input'
                              type="text" name='staff_age'
                              value={staffDetails?.staff_age}
                              onChange={handleAddStaffData} />
                          </div>
                        </div>

                        <div className='blur-form-control'>
                          <label htmlFor="">{t("IMAGEN DEL PERSONAL")}</label>
                          <div className="select-clrImg">

                            <div className="color-picker-btn">
                              <div className='img-edit img-edit-upload'>
                                <GrUpload />
                                <input id="uploadFile" onChange={handleFileSelect}
                                  type="file" className="upload" readOnly />
                              </div>
                            </div>

                            <div className="select-clr-Name">
                              <input
                                type="text"
                                value={staffDetails?.staff_image_url || profile}
                                className="text-white club-selection" />
                            </div>
                          </div>
                        </div>

                        <div className="staff-profile mx-auto"
                          style={{ visibility: `${(file || staffDetails?.staff_image_url) ? 'visible' : 'hidden'}` }}>
                          <span>
                            <img src="./img/x.svg" className="cross-icon-staff"
                              alt="cross-icon" id={staffDetails?.staff__id}
                              onClick={handleCloseBox}></img>
                          </span>
                          <img src={
                            (file && URL.createObjectURL(file)) || profile
                          } className="profile-img" alt="">
                          </img>
                        </div>

                      </div>
                    </form>
                  </div>
                </Modal.Body>

                <Modal.Footer className="border-0">
                  <div className="form-group text-center d-flex">
                    <button className="green-btn mb-0 rounded-pill"
                      onClick={handleAddStaff}>
                      {addWait
                        ? (<>
                          <span className="spinner-border spinner-border-sm"
                            role="status" aria-hidden="true">
                          </span>

                          <span className="sr-only" >
                            {t("Enviando")}...
                          </span>
                        </>)
                        : (<>{t("CREAR")}</>)
                      }
                    </button>

                    {(staffDetails.staff__id)
                      && (<button className="red-btn mb-0 rounded-pill"
                        onClick={() => handleStaffDeletion(staffDetails.staff__id)}>
                        {delBtn
                          ? (<>
                            <span className="spinner-border spinner-border-sm"
                              role="status" aria-hidden="true"></span>
                            <span className="sr-only"> {t("Enviando")}... </span>
                          </>)
                          : (t('Borrar'))
                        }
                      </button>)
                    }

                    <button className="blue-btn mb-0 rounded-pill"
                      onClick={handleClose}> {t("CANCELAR")} </button>
                  </div>
                </Modal.Footer>

              </Modal>

              <Modal show={editorEquipo} className="boardMeeting-popup common-popup custom-pop"
                onHide={handleCloseEquipo} centered>
                <Modal.Body className="modal-body-padding" >
                  <div className='addboardingsec'>

                    <div className='matches-history-card'>
                      <div className="partidos-team-list argent-head">
                        <h4>{juvenilesClassification?.team1_name}</h4>
                        <div className='feild-sec'>

                          <form>
                            <div className='feild-card'>
                              <p>{t("Equipo")}</p>
                              <input type="text" list="data1"
                                name='team2'
                                value={juvenilesClassification?.team2_name}
                                onChange={handleSecondTeam} />
                              <datalist id="data1">
                                {allTeam?.map((member, key) => {
                                  return <option key={key} id={member.team_id} value={member.team_name} />
                                }
                                )}
                              </datalist>
                            </div>

                            <div className='feild-card'>
                              <p>{t("Puntos")}</p>
                              <input type="text" name='points'
                                value={juvenilesClassification?.points}
                                onChange={handleJuvenilesClassification}
                              />

                            </div>
                            <div className='feild-card'>
                              <p>{t("Partidos jugados")}</p>
                              <input type="text" name='matches_played'
                                value={juvenilesClassification?.match_played}
                                onChange={handleJuvenilesClassification}
                              />
                            </div>

                            <div className='feild-card'>
                              <p>{t("Partidos ganados")}</p>
                              <input type="text" name="won_matches"
                                value={juvenilesClassification?.won_matches}
                                onChange={handleJuvenilesClassification}
                              />
                            </div>

                            <div className='feild-card'>
                              <p>{t("Partidos empatados")}</p>
                              <input type="text" name="draw_matches"
                                value={juvenilesClassification?.draw_matches}
                                onChange={handleJuvenilesClassification}
                              />
                            </div>

                            <div className='feild-card'>
                              <p>{t("Partidos perdidos")}</p>
                              <input type="text" name="lost_matches"
                                value={juvenilesClassification?.lost_matches}
                                onChange={handleJuvenilesClassification}
                              />
                            </div>

                            <div className='feild-card'>
                              <p>{t("Goles a favor")}</p>
                              <input type="text" name="goal_favour"
                                value={juvenilesClassification?.goal_favour}
                                onChange={handleJuvenilesClassification}
                              />
                            </div>

                            <div className='feild-card'>
                              <p>{t("Goles en contra")}</p>
                              <input type="text" name="goal_against"
                                value={juvenilesClassification?.goal_against}
                                onChange={handleJuvenilesClassification}
                              />
                            </div>

                            <div className='feild-card'>
                              <p>{t("Diferencia de goles")}</p>
                              <input type="text" name="goal_difference"
                                value={juvenilesClassification?.goal_difference}
                                onChange={handleJuvenilesClassification}
                              />
                            </div>

                            <div className='feild-card'>
                              <p>{t("estado de clasificación del equipo")}</p>
                              <select className="dropdown-qualify" name="wc_qualification"
                                value={juvenilesClassification?.wc_qualification}
                                onChange={handleJuvenilesClassification}>
                                {/* <option value={null} disabled selected hidden >seleccionar estado</option> */}
                                <option value='Calificar'>{t("Calificar")}</option>
                                <option value='repechaje'>{t("repechaje")}</option>
                                <option value='nonqualify'>{t("nonqualify")}</option>
                              </select>
                            </div>

                            <h4>{t("RESULTADOS")}</h4>
                            <div className='feild-card feild-team-score' onClick={handleShowResultados}>
                              <img src={juvenilesClassification?.team1_logo} alt="" />
                              <h5 className='edit-sec' id='diff_position'>
                                <input type="number" name="team1_points"
                                  value={juvenilesClassification?.team1_points}
                                  onChange={handleJuvenilesClassification} />
                              </h5>

                              <p>{t("Último Resultado")}</p>
                              <h5 className='edit-sec' id='diff_position'>
                                <input type="number" name="team2_points"
                                  value={juvenilesClassification?.team2_points}
                                  onChange={handleJuvenilesClassification} />
                              </h5>
                              <img src={juvenilesClassification?.team2_logo} alt="" />
                            </div>
                          </form>

                        </div>

                        <div className="form-group text-center">
                          <button className="green-btn mb-0 rounded-pill"
                            onClick={handleAddYouthClassification}>
                            {addYouthClass
                              ? (<>
                                <span className="spinner-border spinner-border-sm"
                                  role="status" aria-hidden="true">
                                </span>
                                <span className="sr-only" >
                                  {t("Enviando")}...
                                </span>
                              </>)
                              : (<>{t("CREAR")}</>)
                            }
                          </button>
                        </div>
                      </div>

                    </div>
                  </div>
                </Modal.Body>

                <Modal.Footer className="border-0">

                </Modal.Footer>
              </Modal>

              <Modal show={editorOther} className="boardMeeting-popup common-popup custom-pop"
                onHide={handleCloseOther} centered>
                <Modal.Body className="modal-body-padding" >
                  <div className='addboardingsec'>

                    <div className='matches-history-card'>
                      <div className="partidos-team-list argent-head">
                        <h4>{otherClassification?.team1_name}</h4>
                        <div className='feild-sec'>
                          <form>
                            <div className='feild-card'>
                              <p>{t("Equipo")}</p>
                              <input type="text" list="data1"
                                name='team2'
                                value={otherClassification?.team2_name}
                                onChange={handleSecondTeam} />
                              <datalist id="data1">
                                {allTeam?.map((member, key) => {
                                  return <option key={key} id={member.team_id} value={member.team_name} />
                                }
                                )}
                              </datalist>
                            </div>

                            <div className='feild-card'>
                              <p>{t("Puntos")}</p>
                              <input type="text" name='points'
                                value={otherClassification?.points}
                                onChange={handleOtherClassification}
                              />

                            </div>
                            <div className='feild-card'>
                              <p>{t("Partidos jugados")}</p>
                              <input type="text" name='matches_played'
                                value={otherClassification?.match_played}
                                onChange={handleOtherClassification}
                              />
                            </div>

                            <div className='feild-card'>
                              <p>{t("Partidos ganados")}</p>
                              <input type="text" name="won_matches"
                                value={otherClassification?.won_matches}
                                onChange={handleOtherClassification}
                              />
                            </div>

                            <div className='feild-card'>
                              <p>{t("Partidos empatados")}</p>
                              <input type="text" name="draw_matches"
                                value={otherClassification?.draw_matches}
                                onChange={handleOtherClassification}
                              />
                            </div>

                            <div className='feild-card'>
                              <p>{t("Partidos perdidos")}</p>
                              <input type="text" name="lost_matches"
                                value={otherClassification?.lost_matches}
                                onChange={handleOtherClassification}
                              />
                            </div>

                            <div className='feild-card'>
                              <p>{t("Goles a favor")}</p>
                              <input type="text" name="goal_favour"
                                value={otherClassification?.goal_favour}
                                onChange={handleOtherClassification}
                              />
                            </div>

                            <div className='feild-card'>
                              <p>{t("Goles en contra")}</p>
                              <input type="text" name="goal_against"
                                value={otherClassification?.goal_against}
                                onChange={handleOtherClassification}
                              />
                            </div>

                            <div className='feild-card'>
                              <p>{t("Diferencia de goles")}</p>
                              <input type="text" name="goal_difference"
                                value={otherClassification?.goal_difference}
                                onChange={handleOtherClassification}
                              />
                            </div>

                            <div className='feild-card'>
                              <p>{t("estado de clasificación del equipo")}</p>
                              <select className="dropdown-qualify" name="wc_qualification"
                                value={otherClassification?.wc_qualification}
                                onChange={handleJuvenilesClassification}>
                                {/* <option value={null} disabled selected hidden >seleccionar estado</option> */}
                                <option value='Calificar'>{t("Calificar")}</option>
                                <option value='repechaje'>{t("repechaje")}</option>
                                <option value='nonqualify'>{t("nonqualify")}</option>
                              </select>
                            </div>

                            <h4>{t("RESULTADOS")}</h4>
                            <div className='feild-card feild-team-score' onClick={handleShowResultados}>
                              <img src={otherClassification?.team1_logo} alt="" />
                              <h5 className='edit-sec' id='diff_position'>
                                <input type="number" name="team1_points"
                                  value={otherClassification?.team1_points}
                                  onChange={handleOtherClassification} />
                              </h5>

                              <p>{t("Último Resultado")}</p>
                              <h5 className='edit-sec' id='diff_position'>
                                <input type="number" name="team2_points"
                                  value={otherClassification?.team2_points}
                                  onChange={handleOtherClassification} />
                              </h5>
                              <img src={otherClassification?.team2_logo} alt="" />
                            </div>
                          </form>
                        </div>

                        <div className="form-group text-center">
                          <button className="green-btn mb-0 rounded-pill"
                            onClick={handleAddOtherClassification}>
                            {addYouthClass
                              ? (<>
                                <span className="spinner-border spinner-border-sm"
                                  role="status" aria-hidden="true">
                                </span>
                                <span className="sr-only" >
                                  {t("Enviando")}...
                                </span>
                              </>)
                              : (<>{t("CREAR")}</>)
                            }
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                </Modal.Body>

                <Modal.Footer className="border-0">
                  {/* 
                  <div className="form-group text-center d-flex">
                    <button className="green-btn mb-0 rounded-pill"
                      onClick={handleAddOtherClassification}>
                      {addYouthClass
                        ? (<>
                          <span className="spinner-border spinner-border-sm"
                            role="status" aria-hidden="true">
                          </span>
                          <span className="sr-only" >
                            {t("Enviando")}...
                          </span>
                        </>)
                        : (<>{t("CREAR")}</>)
                      }
                    </button>
                  </div>
                   */}
                </Modal.Footer>

              </Modal>

              <Modal show={resultados} className="boardMeeting-popup common-popup custom-pop result-popup-manage"
                onHide={handleCloseResultados} centered>
                <Modal.Body >
                  <div className='addboardingsec'>

                    <div className='matches-history-card'>
                      <div className="partidos-team-list argent-head">
                        <div className='feild-sec'>

                          <form className="resultados-div">

                            <h4>{t("RESULTADOS")}</h4>

                            <div className="custom-drop-wrap">
                              <div className="categories-dropdown">
                                <Dropdown onSelect={handleJornada}>
                                  <Dropdown.Toggle
                                    className="dropdown-btn"
                                    id="dropdown-basic"
                                    defaultactivekey={juvenilesClassification?.jornada}
                                  // active={menuItem}
                                  >
                                    Jornada {juvenilesClassification?.jornada}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item eventKey='1'>
                                      Jornada 1
                                    </Dropdown.Item>

                                    <Dropdown.Item eventKey='2'>
                                      Jornada 2
                                    </Dropdown.Item>

                                    <Dropdown.Item eventKey='3'>
                                      Jornada 3
                                    </Dropdown.Item>

                                    <Dropdown.Item eventKey='4'>
                                      Jornada 4
                                    </Dropdown.Item>

                                    <Dropdown.Item eventKey='5'>
                                      Jornada 5
                                    </Dropdown.Item>
                                  </Dropdown.Menu>

                                </Dropdown>
                              </div>
                            </div>

                            <div className='feild-card feild-team-score' onClick={handleShowResultados}>
                              <img src={juvenilesClassification?.team1_logo} alt="" />
                              <h5 className='edit-sec' id='diff_position'>
                                <input type="number" name="team1_points"
                                  value={juvenilesClassification?.team1_points}
                                  onChange={handleJuvenilesClassification} />
                              </h5>

                              <p>{t("Último Resultado")}</p>

                              <h5 className='edit-sec' id='diff_position'>
                                <input type="number" name="team2_points"
                                  value={juvenilesClassification?.team2_points}
                                  onChange={handleJuvenilesClassification} />
                              </h5>
                              <img src={juvenilesClassification?.team2_logo} alt="" />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>

              </Modal>

              <Modal show={editarCompetenciaYouth} className="boardMeeting-popup common-popup custom-pop"
                onHide={handleCloseCompYouth} centered>
                <Modal.Body className="modal-body-padding" >
                  <div className='addboardingsec'>

                    <div className='matches-history-card'>
                      <div className="partidos-team-list argent-head">
                        <h4>{t("AGREGAR COMPETENCIA")}</h4>
                        <div className='feild-sec'>
                          <form>

                            <div className='feild-card'>
                              <p>{t("Tipo de torneo")}</p>
                              <input type="text" name='Tipo_de_torneo'
                                value={youthCompetencia?.Tipo_de_torneo}
                                onChange={handleYouthCompetencia} />
                            </div>

                            <div className='feild-card'>
                              <p>{t("Cantidad de equipos")}</p>
                              <input type="text" name='Cantidad_de_equipos'
                                value={youthCompetencia?.equipos}
                                onChange={handleYouthCompetencia}
                              />
                            </div>

                            <div className='feild-card'>
                              <p>{t("Cantidad de partidos")}</p>
                              <input type="text" name='Cantidad_de_partidos'
                                value={youthCompetencia?.partidos}
                                onChange={handleYouthCompetencia}
                              />
                            </div>

                          </form>
                        </div>

                        <div className="form-group text-center d-flex">
                          <button className="green-btn mb-0 rounded-pill"
                            onClick={handleAddYouthCompetencia}>
                            {addYouthCompetenciaClass
                              ? (<>
                                <span className="spinner-border spinner-border-sm"
                                  role="status" aria-hidden="true">
                                </span>
                                <span className="sr-only" >&nbsp;
                                  {t("Enviando")}...
                                </span>
                              </>)
                              : (<span>{t("CREAR")}</span>)
                            }
                          </button>

                          <button className="red-btn mb-0 rounded-pill"
                            onClick={handleCloseCompYouth}>
                            {addYouthCompetenciaClass
                              ? (<>
                                <span className="spinner-border spinner-border-sm"
                                  role="status" aria-hidden="true">
                                </span>
                                <span className="sr-only" >&nbsp;
                                  {t("Enviando")}...
                                </span>
                              </>)
                              : (<span>{t("CANCELAR")}</span>)
                            }
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                </Modal.Body>

                {/* <Modal.Footer className="border-0">
                  
                </Modal.Footer> */}
              </Modal>

              <Modal show={editarCompetenciaOther} className="boardMeeting-popup common-popup custom-pop"
                onHide={handleCloseCompOther} centered>
                <Modal.Body className="modal-body-padding" >
                  <div className='addboardingsec'>

                    <div className='matches-history-card'>
                      <div className="partidos-team-list argent-head">
                        <h4>{t("AGREGAR COMPETENCIA")}</h4>
                        <div className='feild-sec'>
                          <form>

                            <div className='feild-card'>
                              <p>{t("Tipo de torneo")}</p>
                              <input type="text" name='Tipo_de_torneo'
                                value={otherCompetencia?.Tipo_de_torneo}
                                onChange={handleOtherCompetencia} />
                            </div>

                            <div className='feild-card'>
                              <p>{t("Cantidad de equipos")}</p>
                              <input type="text" name='Cantidad_de_equipos'
                                value={otherCompetencia?.equipos}
                                onChange={handleOtherCompetencia}
                              />
                            </div>

                            <div className='feild-card'>
                              <p>{t("Cantidad de partidos")}</p>
                              <input type="text" name='Cantidad_de_partidos'
                                value={otherCompetencia?.partidos}
                                onChange={handleOtherCompetencia}
                              />
                            </div>
                          </form>
                        </div>

                        <div className="form-group text-center d-flex">
                          <button className="green-btn mb-0 rounded-pill"
                            onClick={handleAddOtherCompetencia}>
                            {addYouthCompetenciaClass
                              ? (<>
                                <span className="spinner-border spinner-border-sm"
                                  role="status" aria-hidden="true">
                                </span>
                                <span className="sr-only" >&nbsp;
                                  {t("Enviando")}...
                                </span>
                              </>)
                              : (<span>{t("CREAR")}</span>)
                            }
                          </button>

                          <button className="red-btn mb-0 rounded-pill"
                            onClick={handleCloseCompOther}>

                            {addYouthCompetenciaClass
                              ? (<>
                                <span className="spinner-border spinner-border-sm"
                                  role="status" aria-hidden="true">
                                </span>
                                <span className="sr-only" >&nbsp;
                                  {t("Enviando")}...
                                </span>
                              </>)
                              : (<span>{t("CANCELAR")}</span>)
                            }
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                </Modal.Body>

                {/* <Modal.Footer className="border-0">
                  
                </Modal.Footer> */}
              </Modal>

            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamAdministration;